import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import { OktaConfig } from './_okta/config/OktaConfig';

import { Routes } from './routes/Routes.jsx';
import ScrollToTop from './components/subcomponents/ScrollToTop';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 5 * 60 * 1000,
    },
    mutations: {
      retry: 0,
    },
  },
});

export default function App() {
  const history = useHistory();

  const oktaAuth = new OktaAuth({
    clientId: OktaConfig.clientId,
    issuer: OktaConfig.issuer,
    redirectUri: OktaConfig.redirectUri,
    scopes: OktaConfig.scopes,
    pkce: OktaConfig.pkce,
  });

  useEffect(() => {
    fetch('/nonce')
      .then((response) => response.json())
      .then((data) => {
        // Setup the google tag manager code.
        const script = document.createElement('script');
        script.nonce = data.nonce;
        script.innerHTML = `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;j.setAttribute('nonce', '${data.nonce}');
          f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.REACT_APP_GTM_TAG}');
        `;
        // Add the script to the document.
        document.head.insertBefore(script, document.head.childNodes[0]);
      });
  });

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <QueryClientProvider client={queryClient}>
      <div className="portal">
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Router>
            <ScrollToTop>
              <Routes />
            </ScrollToTop>
          </Router>
        </Security>
      </div>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
