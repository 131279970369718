import { useState } from 'react';

const Accordion = ({ title, closeTitle = '', startOpen = false, id = null, iconElementOpen = '', iconElementClose = '', children}) => {
  const [open, setOpen] = useState(startOpen);

  if (!closeTitle) {
    closeTitle = title;
  }

  const headerID = id ? `${id}_header` : null;
  const detailsID = id ? `${id}_details` : null;

  const handleEnter = (e) => {
    if (e.code === 'Enter') {
      setOpen(!open);
    }
  };

  // If we need the expansion icon to match the styles of the title, set an element as a wrapper. 
  const closeClasses = `close-icon ${iconElementClose}` ;
  const openClasses = `open-icon ${iconElementOpen}` ;
  return (
    <div className={open ? 'accordion-item open' : 'accordion-item'}>
      <div
        className="accordion-header"
        onClick={() => setOpen(!open)}
        onKeyDown={handleEnter}
        role="button"
        tabIndex="0"
        aria-expanded={open ? 'true' : 'false'}
        id={headerID}
        aria-controls={detailsID}
      >
        <div className="accordion-title">
          <span className="open-title">{title}</span>
          <span className="close-title">{closeTitle}</span>
        </div>
        <div className="accordion-icon" aria-hidden="true">
          <span className={openClasses}>+</span>
          <span className={closeClasses}>&ndash;</span>
        </div>
      </div>
      <div
        className="accordion-details"
        id={detailsID}
        aria-labelledby={headerID}
        role="region"
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
