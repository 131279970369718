import { Field } from 'formik';
import selectOptions from '../../../data/selectOptions.json';
import Accordion from '../../subcomponents/Accordion';
import { useState, useEffect } from 'react';

export default function ContactInfoForm({values, errors, touched, handleChange, handleBlur}) {
    const phoneOptions = selectOptions.phoneType;
    const stateOptions = selectOptions.state;
    const [errorInSection,setErrorInSection] = useState(false);
    
    /* highlight section title if any fields in this section have thrown an error! */
    useEffect(()=>{
        const fieldArray = [
            "personalEmail",
            "homeAddress",
            "homeCity",
            "homeState",
            "homeZip",
            "personalPhone",
            "homePhoneType",
            "businessEmail",
            "businessAddress",
            "businessCity",
            "businessState",
            "businessZip",
            "businessPhone",
            "businessPhoneType",
        ];
        const matchingErrors = Object.keys(errors).filter(element => fieldArray.includes(element));
//        console.log(matchingErrors);
        if(matchingErrors.length > 0){
            setErrorInSection(true);
        } else {
            setErrorInSection(false);
        }
        
    },[errors]);
    
    return (
     <Accordion id="test2" title=<h4 className={errorInSection?'error red-text':''}>Contact Information</h4> closeTitle=<h4 className={errorInSection?'error red-text':''}>Contact Information</h4> iconElementClose='title3' 
        iconElementOpen='title4' >   
     <div className="column-layout">
        <div className="col">
          <div className="field">
            <label htmlFor="personalemail-field">Personal Email <span className="req-star">*</span></label>
            <Field
                type="email"
                id="personalemail-field"
                name="personalEmail"
                value={values.personalEmail}
                className={errors?.personalEmail ? 'error' : null}
                onChange={handleChange}
                placeholder="Personal Email"
                onBlur={handleBlur}
                required={true}
              />
              { errors?.personalEmail && (
                <span className="red-text">{errors?.personalEmail}</span>
              )}
        </div>
        <div className="field">        
                <ul
                  className="radios"
                  role="group"
                  aria-labelledby="primaryEmail">
                  <li>
                  <Field
                  type="radio"
                  id="primaryEmail"
                  name="primaryEmail"
                  value="R"
                  checked={values.primaryEmail==='R'}
                  onChange={handleChange}
                />
                <label htmlFor="primaryEmail">
                    Use as primary email address
                    </label> </li></ul>
            </div>   
      </div>
      <div className="col">
            
            <div className="field">
                <label htmlFor="personalphone-field">Personal Phone Number <span className="req-star">*</span></label>
                <Field
                    type="phone"
                    id="personalphone-field"
                    name="personalPhone"
                    value={values.personalPhone}
                    className={errors.personalPhone ? 'error' : null}
                    onChange={handleChange}
                    placeholder="Personal Phone"
                    onBlur={handleBlur}
                    required={true}
                  />
                  {errors.personalPhone && (
                    <span className="red-text">{errors.personalPhone}</span>
                  )}
            </div>
            <div className="field"> 
            <ul
                  className="radios"
                  role="group"
                  aria-labelledby="primaryPhone">
                  <li>
                <Field
                  type="radio"
                  id="primaryPhone"
                  name="primaryPhone"
                  value="R"
                  checked={values.primaryPhone==='R'}
                  onChange={handleChange}
                />
                <label htmlFor="primaryPhone">
                    Use as primary phone number
                    </label></li></ul>
            </div>   
            </div></div>
      <div className="column-layout">      
      <div className="col">
            <div className="field">
                <label htmlFor="homephonetype-field">Personal Phone Type <span className="req-star">*</span></label>
                <select
                    id="homephonetype-field"
                    name="homePhoneType"
                    value={values.homePhoneType}
                    onChange={handleChange}
                    required={true}>
                    {phoneOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  </div></div></div>
             <div className="column-layout">      
      <div className="col">
            <div className="field">
                <label htmlFor="homeaddress-field">Home Address <span className="req-star">*</span></label>
                <Field
                    type="address"
                    id="homeaddress-field"
                    name="homeAddress"
                    value={values.homeAddress}
                    className={errors.homeAddress ? 'error' : null}
                    onChange={handleChange}
                    placeholder="Home Address"
                    onBlur={handleBlur}
                    required={true}
                  />
                  {errors.homeAddress && (
                    <span className="red-text">{errors.homeAddress}</span>
                  )}
            </div></div>
            <div className="col">
            <div className="field">
                <label htmlFor="homeaddress2-field">Home Address 2</label>
                <Field
                    type="address"
                    id="homeaddress2-field"
                    name="homeAddress2"
                    value={values.homeAddress2}
                    className={errors.homeAddress2 ? 'error' : null}
                    onChange={handleChange}
                    placeholder="Home Address 2"
                    onBlur={handleBlur}
                    required={false}
                  />
                  {errors.homeAddress2 && (
                    <span className="red-text">{errors.homeAddress2}</span>
                  )}
            </div></div></div>
             <div className="column-layout">      
            <div className="col">
            <div className="field">
                <label htmlFor="homecity-field">Home City<span className="req-star">*</span></label>
                <Field
                    type="city"
                    id="homecity-field"
                    name="homeCity"
                    value={values.homeCity}
                    className={errors.homecity ? 'error' : null}
                    onChange={handleChange}
                    placeholder="Home City"
                    onBlur={handleBlur}
                    required={true}
                  />
                  {errors.homecity && (
                    <span className="red-text">{errors.homecity}</span>
                  )}
          </div></div>
           <div className="col">
            <div className="field">
                <label htmlFor="homestate-field">Home State<span className="req-star">*</span></label>
                <select
                    id="homestate-field"
                    name="homeState"
                    value={values.homeState}
                    onChange={handleChange}
                    required={true}>
                    {stateOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {errors.homeState && (
                    <span className="red-text">{errors.homeState}</span>
                  )}
            </div> </div></div>
             <div className="column-layout">
             <div className="col">
            <div className="field">
                <label htmlFor="homezip-field">Home Postal Code<span className="req-star">*</span></label>
                <Field
                    type="zip"
                    id="homezip-field"
                    name="homeZip"
                    value={values.homeZip}
                    className={errors.homeZip ? 'error' : null}
                    onChange={handleChange}
                    placeholder="Home Postal Code"
                    onBlur={handleBlur}
                    required={true}
                  />
                  {errors.homeZip && (
                    <span className="red-text">{errors.homeZip}</span>
                  )}
            </div>
            
            <div className="field">      
            <ul
                  className="radios"
                  role="group"
                  aria-labelledby="primaryAddress">
                  <li>
                <Field
                  type="radio"
                  id="primaryAddress"
                  name="primaryAddress"
                  value="R"
                  checked={values.primaryAddress==='R'}
                  onChange={handleChange}
                />
                <label htmlFor="primaryAddress">
                    Use as primary address
                    </label></li></ul>
            </div>  
            {/*_________*/}
            </div></div>
             <div className="column-layout"> 
             <div className="col">
            <div className="field">
            <label htmlFor="businessemail-field">Business Email <span className="req-star">*</span></label>
            <Field
                type="email"
                id="businessemail-field"
                name="businessEmail"
                value={values.businessEmail}
                className={errors.businessEmail ? 'error' : null}
                onChange={handleChange}
                placeholder="Business Email"
                onBlur={handleBlur}
                required={true}
              />
              {errors.businessEmail && (
                <span className="red-text">{errors.businessEmail}</span>
              )}
            </div>
            <div className="field">    
            <ul
                  className="radios"
                  role="group"
                  aria-labelledby="primaryEmailB">
                  <li>
                <Field
                  type="radio"
                  id="primaryEmailB"
                  name="primaryEmail"
                  value="B"
                  checked={values.primaryEmail==='B'}
                  onChange={handleChange}
                />
                <label htmlFor="primaryEmailB">
                    Use as primary email address
                    </label></li></ul>
            </div>  </div>
      <div className="col">  
            <div className="field">
                <label htmlFor="businessphone-field">Business Phone Number <span className="req-star">*</span></label>
                <Field
                    type="phone"
                    id="businessphone-field"
                    name="businessPhone"
                    value={values.businessPhone}
                    className={errors.businessPhone ? 'error' : null}
                    onChange={handleChange}
                    placeholder="Business Phone"
                    onBlur={handleBlur}
                    required={true}
                  />
                  {errors.businessPhone && (
                    <span className="red-text">{errors.businessPhone}</span>
                  )}
            </div>
            <div className="field">        
            <ul
                  className="radios"
                  role="group"
                  aria-labelledby="primaryPhoneB">
                  <li>
                <Field
                  type="radio"
                  id="primaryPhoneB"
                  name="primaryPhone"
                  value="B"
                  checked={values.primaryPhone==='B'}
                  onChange={handleChange}
                />
                <label htmlFor="primaryPhoneB">
                    Use as primary phone number
                    </label></li></ul>
            </div>   </div></div>
      <div className="column-layout">      
      <div className="col">
            <div className="field">
                <label htmlFor="busphonetype-field">Business Phone Type <span className="req-star">*</span></label>
                <select
                    id="busphonetype-field"
                    name="businessPhoneType"
                    value={values.businessPhoneType}
                    onChange={handleChange}
                    required={true}>
                    {phoneOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
            </div></div></div>
      <div className="column-layout">      
      <div className="col">
            <div className="field">
                <label htmlFor="businessaddress-field">Business Address <span className="req-star">*</span></label>
                <Field
                    type="address"
                    id="businessaddress-field"
                    name="businessAddress"
                    value={values.businessAddress}
                    className={ errors.businessAddress ? 'error' : null}
                    onChange={handleChange}
                    placeholder="Business Address"
                    onBlur={handleBlur}
                    required={true}
                  />
                  {errors.businessAddress && (
                    <span className="red-text">{errors.businessAddress}</span>
                  )}
          </div> </div>
          <div className="col">
            <div className="field">
                <label htmlFor="businessaddress2-field">Business Address 2</label>
                <Field
                    type="address"
                    id="businessaddress2-field"
                    name="businessAddress2"
                    value={values.businessAddress2}
                    className={errors.businessAddress2 ? 'error' : null}
                    onChange={handleChange}
                    placeholder="Business Address 2"
                    onBlur={handleBlur}
                    required={false}
                  />
                  {errors.businessAddress2 && (
                    <span className="red-text">{errors.businessAddress2}</span>
                  )}
            </div>
            </div></div>
      <div className="column-layout">      
      <div className="col">
            <div className="field">
                <label htmlFor="businesscity-field">Business City<span className="req-star">*</span></label>
                <Field
                    type="city"
                    id="businesscity-field"
                    name="businessCity"
                    value={values.businessCity}
                    className={errors.businessCity ? 'error' : null}
                    onChange={handleChange}
                    placeholder="Business City"
                    onBlur={handleBlur}
                    required={true}
                  />
                  {errors.businessCity && (
                    <span className="red-text">{errors.businessCity}</span>
                  )}
          </div></div>
          <div className="col">
            <div className="field">
                <label htmlFor="businessstate-field">Business State<span className="req-star">*</span></label>
                <select
                id="businessstate-field"
                name="businessState"
                value={values.businessState}
                onChange={handleChange}
                required={true}>
                {stateOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
                  {errors.businessState && (
                    <span className="red-text">{errors.businessState}</span>
                  )}
            </div></div></div>
      <div className="column-layout">      
      <div className="col">
            <div className="field">
                <label htmlFor="buszip-field">Business Postal Code<span className="req-star">*</span></label>
                <Field
                    type="zip"
                    id="buszip-field"
                    name="businessZip"
                    value={values.businessZip}
                    className={errors.businessZip ? 'error' : null}
                    onChange={handleChange}
                    placeholder="Home Postal Code"
                    onBlur={handleBlur}
                    required={true}
                  />
                  { errors.businessZip && (
                    <span className="red-text">{errors.businessZip}</span>
                  )}
          </div>
            <div className="field">        
                <ul
                  className="radios"
                  role="group"
                  aria-labelledby="primaryAddressB">
                  <li>
                    <Field
                      type="radio"
                      id="primaryAddressB"
                      name="primaryAddress"
                      value="B"
                      checked={values.primaryAddress==='B'}
                      onChange={handleChange}
                    />
                    <label htmlFor="primaryAddressB">
                        Use as primary address
                    </label>
                    </li></ul>
                </div>  </div></div>
    </Accordion>
    );
}
