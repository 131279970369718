import { useQuery } from 'react-query';
import useTacfsService from '../../utils/tacfs/useTacfsService';
import { Link } from 'react-router-dom';

const CompanyReportNav = () => {
  // Load the tacfs helper Service Worker.
  const { load } = useTacfsService();
  const { isLoading, data } = useQuery(['studentFlags'], () =>
    load('studentFlags'),
  );

  return (
    <>
      {isLoading === false && data && data.reporting_user === 'Y' && (
        <div className="company-report-banner">
          <div className="container flex">
            <p className="p2 font-bold">Company Representative</p>
            <Link
              to={`/company-portal`}
              relative="path"
            >
              Company Reports
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default CompanyReportNav;
