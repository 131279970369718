import SetStorage from '../components/storage/SetStorage';

function calculateProfileCompletion(profile) {
  let persTotal = 0,
    profTotal = 0;
  persTotal +=
    profile.basic.national_id?.trim() ||
    profile.flags.ssn_opt_out?.trim() === 'Y'
      ? 20
      : 0;
  persTotal +=
    profile.contact.address1?.trim() &&
    profile.contact.city?.trim() &&
    profile.contact.state?.trim() &&
    profile.contact.postal?.trim()
      ? 7
      : 0;
  persTotal += profile.contact.phone?.trim() ? 7 : 0;
  persTotal += profile.contact.email?.trim() ? 6 : 0;
  persTotal +=
    profile.contact.address1_work?.trim() &&
    profile.contact.city_work?.trim() &&
    profile.contact.state_work?.trim() &&
    profile.contact.postal_work?.trim()
      ? 7
      : 0;
  persTotal += profile.contact.phone_work?.trim() ? 7 : 0;
  persTotal += profile.contact.email_alternate?.trim() ? 6 : 0;
  persTotal += profile.basic.birthdate?.trim() ? 2 : 0;
  persTotal += profile.basic.military_flag?.trim() ? 2 : 0;
  persTotal += profile.basic.tac_citizenship_cd?.trim() ? 2 : 0;
  persTotal += profile.basic.sex?.trim() ? 2 : 0;
  persTotal += profile.basic.tac_ethnic_group_cd?.trim() ? 2 : 0;

  profTotal += profile.company.company_id?.trim() ? 20 : 0;
  profTotal += profile.education.number_of_years?.trim() ? 2 : 0;
  profTotal += profile.education.income_level?.trim() ? 2 : 0;
  profTotal += profile.education.prev_online_education?.trim() ? 2 : 0;

  const educationBg = [
    profile.education.hs_diploma?.trim(),
    profile.education.some_college?.trim(),
    profile.education.associate_degree?.trim(),
    profile.education.bachelor_degree?.trim(),
    profile.education.master_degree?.trim(),
    profile.education.prof_degree?.trim(),
    profile.education.doctorat_degree?.trim(),
  ];
  profTotal +=
    educationBg.includes('Y') || profile.education.other_education?.trim()
      ? 2
      : 0;

  const learningStyle = [
    profile.education.self_directed?.trim(),
    profile.education.high_structure?.trim(),
    profile.education.support_service?.trim(),
    profile.education.trad_education_prog?.trim(),
  ];
  profTotal +=
    learningStyle.includes('Y') || profile.education.learning_style?.trim()
      ? 2
      : 0;

  SetStorage('percentComplete', persTotal + profTotal);
  SetStorage('persComplete', persTotal === 70);
  SetStorage('profComplete', profTotal === 30);
  return persTotal + profTotal;
}

export default calculateProfileCompletion;
