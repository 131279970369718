import { useContext, useEffect, useState } from 'react';
import { TacfsContext } from '../global/Context';

export function AuthDetails({ page = '', toolkit = '' }) {
  const context = useContext(TacfsContext);
  const [designations, setDesignations] = useState([]);

  useEffect(() => {
    if (
      context &&
      context.tacfsContext.studentInfoInitial?.completed_designation
    ) {
      setDesignations(
        context.tacfsContext.studentInfoInitial.completed_designation.split(
          ',',
        ),
      );
    }
  }, [context]);

  if (Object.keys(context.tacfsContext).length === 0) {
    return <div>Loading</div>;
  } else {
    return (
      <>
        {context.tacfsContext && (
          <div id="authDetails">
            <Message
              userData={context.tacfsContext.studentInfoInitial}
              page={page}
              toolkit={toolkit}
            />
            <div className="user-info">
              <span>
                <strong>Student ID:</strong>{' '}
                <span id="authDetailsStudentId">
                  {context.tacfsContext.studentInfoInitial.empl_id}
                </span>
              </span>
              <br />
              {context.tacfsContext.studentInfoInitial.completed_designation?.trim() && (
                <>
                  <span>
                    <strong>
                      {designations.map((des, i) => {
                        let reg = `\u00AE`; //Unicode version of &reg;
                        if (des.toLowerCase() === 'phd') {
                          reg = '';
                        }
                        if (des !== 'CFP') {
                          if (i === designations.length - 1) {
                            return (
                              <span key={des}>
                                {des}
                                {reg}
                              </span>
                            );
                          } else {
                            return (
                              <span key={des}>
                                {des}
                                {reg},{' '}
                              </span>
                            );
                          }
                        } else {
                          return null;
                        }
                      })}
                    </strong>
                  </span>
                  <br />
                </>
              )}
              <span>
                <strong>Company:</strong>{' '}
                <span id="authDetailsCompanyName">
                  {context.tacfsContext.studentInfoInitial.company_name}
                </span>
              </span>
              <br />
            </div>
          </div>
        )}
      </>
    );
  }
}

function Message({ userData, page, toolkit = '' }) {
  let title = '';

  switch (page) {
    case 'message center':
      title = `${userData.first_name}'s Message Center`;
      break;
    case 'learning journey':
      title = 'My Learning Journey';
      break;
    case 'designations':
      title = 'My Designations & Certifications';
      break;
    case 'tax forms':
      title = `${userData.first_name}'s Tax Forms`;
      break;
    case 'purchase history':
      title = `${userData.first_name}'s Purchase History`;
      break;
    case 'toolkits':
      title = `Your ${toolkit} Advisor Toolkit`;
      break;
    case 'subscriptions':
      title = `My Subscription`;
      break;
    default:
      title = `Hi ${userData.first_name}`;
  }
  /* So we can render &reg; and superscripts */
  return (
    <h1 id="authDetailsName" dangerouslySetInnerHTML={{ __html: title }} />
  );
}
