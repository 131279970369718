import { useState, useEffect } from 'react';
import { useField } from 'formik';

import SelectSearch from './SelectSearch';

// add defaultValue to allow the field to be edited

// Type requirements
// ================================
// type="name" => min length 3 char
// type="email" => must be valid email address
// type="phone" => auto-format xxx-xxx-xxxx with default 000-000-0000
// type="userName" => min length? - check against current active usernames
// type="confirmUser" => 'username' field required
// type="password" => requirements? for now must contain upper, lower, number, and special
// type="confirmPassword" => 'password' field required
// type="securityAnswer" => min length 3 char
// type="ssn" => hidden input => auto format
// type="address" => Google Places API? format requirements?
// type="city" => auto-populates via Google Places API possibly
// type="zipcode" => min length 5 char => do we want to auto-populate full zipcode(5char+4char)?

// Label
// =================================
// must be a string
// sets displayed label={label}
// sets label htmlFor={label}
// sets input id={label}

// Base Styling
// =================================
// "w-full" on input box except with the label of "zipcode"
// strong tags on label

// className
// ==================================
// defaults = "w-full"

// !!!
// if we don't like the length and regex validations happening as user types, we can move everything inside the useEffect to the thisChange function. Should take minimal refactoring and the validation will happen when the user leaves the input field
// !!!

const InputField = (props) => {
  const {
    type,
    label,
    onChange,
    value,
    readOnly,
    required = false,
    srOnly = false,
    defaultValue,
    numbers,
    tab = '',
    id = '',
    className = '',
    wrapperClass = 'inline-field',
    maximumLength = 50,
  } = props;
  const [input, setInput] = useState(value);
  const [error /*, setError*/] = useState([]);
  const [ssn /*, setSsn*/] = useState(false);
  //  const [/*inputReq, */setInputReq] = useState(0);
  let placeholder = '';
  let thisClass = className;
  let maxLength = maximumLength;
  //  const validEmailRegex = RegExp(
  //    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  //  );
  //  const validPhoneRegex = RegExp(
  //    /^[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
  //  );
  //  const validPasswordRegex = RegExp(
  //    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/,
  //  );
  //  const validSsnRegex = RegExp(
  //    /^(?!0{3})(?!6{3})[0-8]\d{2}-(?!0{2})\d{2}-(?!0{4})\d{4}$/,
  //  );
  //  const validZipcodeRegex = RegExp(/^\d{5}$/);

  // prepares input type and input requirements
  let formType = '';
  let requirement = {
    length: 0,
    format: null,
  };
  switch (type) {
    case 'name':
      formType = 'text';
      requirement.length = 3;
      break;
    case 'email':
      formType = 'email';
      requirement.length = 5;
      requirement.format = 'email';
      requirement.regex = '[a-z0-9._%+\\-]+@[a-z0-9.\\-]+\\.[a-z]{2,15}$';
      break;
    case 'phone':
      formType = 'text';
      requirement.length = 12;
      requirement.format = 'phone';
      requirement.regex =
        '^[\\(]?[0-9]{3}[\\)]?[\\-\\s\\.]?[0-9]{3}[\\-\\s\\.]?[0-9]{4,6}$';
      break;
    case 'userName':
      formType = 'text';
      requirement.length = 3;
      break;
    case 'confirmUser':
      formType = 'text';
      requirement.length = 5;
      break;
    case 'password':
      formType = 'password';
      requirement.length = 8;
      requirement.format = 'password';
      break;
    case 'confirmPassword':
      formType = 'password';
      requirement.length = 8;
      break;
    case 'securityAnswer':
      formType = 'text';
      requirement.length = 3;
      break;
    case 'ssn':
      formType = 'text';
      requirement.length = 15;
      requirement.format = 'ssn';
      break;
    case 'ssnReturning':
      formType = 'text';
      requirement.length = 15;
      requirement.format = 'ssnReturning';
      break;
    case 'address':
      formType = 'text';
      requirement.length = 8;
      break;
    case 'city':
      formType = 'text';
      requirement.length = 3;
      break;
    case 'zip':
      formType = 'text';
      requirement.length = 5;
      requirement.format = 'zip';
      requirement.regex = '^[0-9]{5}$';
      break;
    case 'date':
      formType = 'date';
      break;
    default:
      formType = 'text';
      requirement.length = 3;
      break;
  }
  useEffect(() => {
    //    setInputReq(requirement.length);
    setInput(value);
    validate();
    //    maxLength = 30;
    if (defaultValue) {
      document.getElementById(id).defaultValue = value;
    } else {
      document.getElementById(id).value = value;
    }
    if (readOnly) {
      document.getElementById(id).value = value;
      document.getElementById(id).readOnly = true;
    }
    if (numbers) {
      document.getElementById(id).onkeydown = (e) => {
        if (e.key !== 'Backspace') {
          return /[\d]/i.test(e.key);
        }
      };
    }
  }, [
    input,
    value,
    defaultValue,
    id,
    numbers,
    readOnly /*, requirement, setInputReq*/,
  ]);

  const validate = () => {
    /* if (requirement.format === "email" && input.length > 0) {
      validEmailRegex.test(input) ? setError([]) : setError(["Email is not valid"]);
    } else if (requirement.format === "phone" && input.length > 0) {
      validPhoneRegex.test(input) ? setError([]) : setError(["Phone number is not valid"]);
    } else if (requirement.format === "password" && input.length > 0) {
      validPasswordRegex.test(input) ? setError([]) : setError(["Password is not valid"]);
    }  */
    /*else if (requirement.format === "ssn" && input.length > 0) {
      validSsnRegex.test(input) ? setError([]) : setError(["Social Security Number is not valid"]);
    } else if (requirement.format === "ssnReturning" && input.length > 0) {
      validSsnRegex.test(input) ? setError([]) : setError(["Social Security Number is not valid"]);
    }*/
    /* else if (requirement.format === "ssn") {
      setSsn(true);
    } else if (requirement.format === "zip" && input.length > 0) {
      validZipcodeRegex.test(input) ? setError([]) : setError(["Zipcode is not valid"]);
    } else if (requirement.format === null && input.length > 0 && input.length < inputReq) {
      setError([`${label} must be at least ${inputReq} long`]);
    } else {
      setError([]);
    } */
  };

  if (type === 'phone') {
    placeholder = '000-000-0000';
    maxLength = 12;
  }
  if (type === 'ssn') {
    placeholder = '000-00-0000';
    maxLength = 11;
  }
  if (type === 'zip') {
    maxLength = 5;
  }
  if (id === 'ccNumber') {
    maxLength = 16;
  }
  if (id === 'cvvNumber') {
    maxLength = 4;
  }

  return (
    <div className={`${wrapperClass} inputField`}>
      <label htmlFor={id} className={srOnly ? 'sr-only' : ''}>
        {label}
        {required && <span> *</span>}
        {ssn && (
          <button
            aria-disabled
            className="text-blue-600 hover:text-blue-700"
            data-bs-placement="top"
            data-bs-toggle="tooltip"
            title="We use your SSN to verify your identity"
          >
            Why is this Required?
          </button>
        )}
      </label>
      {required ? (
        <input
          className={thisClass}
          type={formType}
          id={id}
          placeholder={placeholder}
          onChange={onChange}
          tabIndex={tab}
          maxLength={maxLength}
          pattern={requirement.regex}
          required
        />
      ) : (
        <input
          className={thisClass}
          type={formType}
          id={id}
          placeholder={placeholder}
          onChange={onChange}
          tabIndex={tab}
          maxLength={maxLength}
          pattern={requirement.regex}
        />
      )}
      {error.map((err, index) => {
        return <span key={index}>{err}</span>;
      })}
    </div>
  );
};

const FormikText = ({ label, formikRequired, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <label htmlFor={props.id}>
        {label} {formikRequired && <span className="req-star">*</span>}
      </label>
      <input {...field} {...props} />
      {meta.touched && meta.error && (
        <div className="red-text">{meta.error}</div>
      )}
    </>
  );
};

const FormikDatePicker = ({ label, formikRequired, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <label htmlFor={props.id}>
        {label} {formikRequired && <span className="req-star">*</span>}
      </label>
      <input type="date" {...field} {...props} />
      {meta.touched && meta.error && (
        <div className="red-text">{meta.error}</div>
      )}
    </>
  );
};

const FormikTextarea = ({ label, formikRequired, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <label htmlFor={props.id}>
        {label} {formikRequired && <span className="req-star">*</span>}
      </label>
      <textarea {...field} {...props} />
      {meta.touched && meta.error && (
        <div className="red-text">{meta.error}</div>
      )}
    </>
  );
};

const FormikSelect = ({ label, formikRequired, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <label htmlFor={props.id}>
        {label} {formikRequired && <span className="req-star">*</span>}
      </label>
      <select {...field} {...props} />
      {meta.touched && meta.error && (
        <div className="red-text">{meta.error}</div>
      )}
    </>
  );
};

const FormikSearchSelect = ({
  label,
  formikRequired,
  showLabels = true,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <>
      {showLabels && (
        <label htmlFor={props.id}>
          {label} {formikRequired && <span className="req-star">*</span>}
        </label>
      )}
      <SelectSearch {...field} {...props} />
      {meta.touched && meta.error && (
        <div className="red-text">{meta.error}</div>
      )}
    </>
  );
};

const FormikCheckbox = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  return (
    <>
      <input type="checkbox" {...field} {...props} />
      <div className="checkbox-input" aria-hidden="true">
        {children}
      </div>
      <label className="checkbox-input sr-only" htmlFor={props.id}>
        {children}
      </label>
      {meta.touched && meta.error ? (
        <div className="red-text">{meta.error}</div>
      ) : null}
    </>
  );
};

export default InputField;
export {
  FormikText,
  FormikDatePicker,
  FormikTextarea,
  FormikSelect,
  FormikCheckbox,
  FormikSearchSelect,
};
