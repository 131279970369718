// Constants defining the companies that have custom labels, fields, or field names.
export const CustomizedCompanies = {
  _STATEFARM: '1000376',
  _EDWARDJONES: '1000653',
  _JPM: '1000815',
  _ALLSTATE: '1000014',
  _EQUITABLE: '1000116',
  _PRUDENTIAL: '1000334',
  _THRIVENT: '1000137',
  _WESTERNSOUTHERN: '1000426',
  _KNIGHTSCOLUMBUS: '1000201',
  _FARMERS: '1000125',
  _NORTHWESTERNMUTUAL: '1000277',
};
// Constants defining the various 'other' types from our company list.
export const OtherCompanyIds = {
  _INDEPENDENT: '1000927',
  _OTHER_FIN: '1619703',
  _OTHER: 'other',
  _NONPROFIT: '1365974',
  _BROKER_NOT_LISTED: '1599250',
  _ATTORNEY: '1619702',
  _CPA: '1619701',
};
export const TranscriptReportCompanies = {
  _STATEFARM: '1000376',
  _NWMUTUAL: '1000277',
  _PRUDENTIAL: '1000334',
};
export const DefaultAdvisorObject = {
  studentID: '',
  first_name: '',
  last_name: '',
  title: '',
  state: '',
  asset_range: '',
  payment_method: '',
  opt_in: '',
  profile_photo: '',
  address_type: 'BUSN',
  address1: '',
  address2: '',
  city: '',
  postal: '',
  phone: '',
  email_address: '',
  retired: '',
  company_id: '',
  company_name: '',
  broker_id: '',
  linkedin: '',
  facebook: '',
  website: '',
  fiduciary_advisor: '',
  advisor_expertise: [],
  advisor_language: [],
  files: [],
};
//TODO: Allow for hyphens
export const ssnRegex = /^(?!666|000|111|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/;
