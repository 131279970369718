import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
import useTacfsService from '../../utils/tacfs/useTacfsService';
import { FormikText } from '../subcomponents/InputField';

export default function DesignationAppUpdateForm({
  empl_id,
  applid,
  acad_plan,
  setEnableCloseButton,
}) {
  const { save } = useTacfsService();
  const [error, setError] = useState('');
  const [sent, setSent] = useState(false);
  // Get QueryClient from the context
  const queryClient = useQueryClient();
  //console.log(profile);
  const { /*isLoading,*/ mutate: designationApp } = useMutation((values) =>
    save('designationApp', values),
  );

  const formSubmit = async (values) => {
    let params = { studentID: empl_id };
    let data = { ...values };

    data.appid = applid;
    data.emplid = empl_id;

    params.data = data;
    console.log(params);
    designationApp(params, {
      onError: (res) => setError(res),
      onSuccess: (data) => {
        console.log(data);
        if (data.error === 'S') {
          setSent(true);
          setEnableCloseButton(true);
        } else {
          setError(`There was an error with the form submission.`);
        }
        // Invalidate every query with a key that starts with `todos`
        queryClient.invalidateQueries({ queryKey: ['designationAppStatus'] });
      },
    });
  };

  // Show the confirmation message when submission is sent.
  if (sent) {
    return (
      <p className="title2 page-header">
        Thank you for updating your supervisor!
      </p>
    );
  } else {
    return (
      <>
        {/*error && <p className="red-text">{error}</p>*/}
        <Formik
          initialValues={{
            acad_plan: acad_plan,
            supervisor_name: '',
            supervisor_email: '',
          }}
          enableReinitialize={true}
          validationSchema={ValidationSchema}
          onSubmit={formSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setErrors,
          }) => (
            <form onSubmit={handleSubmit} id="designationAppForm">
              <h2>Update Application Information</h2>
              <p>
                Please provide us with your manager’s current name and email
                address. Your manager will be sent a copy of this application,
                so they can confirm that all provided information is accurate,
                including the amount of time you have been employed at your
                current organization.
              </p>

              <div className="field-row">
                <div className="field">
                  <FormikText
                    label="Supervisor's Name"
                    type="text"
                    id="supervisor_name"
                    name="supervisor_name"
                    value={values.supervisor_name}
                    onChange={handleChange}
                    formikRequired
                  />
                </div>
                <div className="field">
                  <FormikText
                    label="Supervisor's Email"
                    type="email"
                    id="supervisor_email"
                    name="supervisor_email"
                    value={values.supervisor_email}
                    onChange={handleChange}
                    formikRequired
                  />
                </div>
              </div>
              <div className="buttons">
                <button type="submit" form="designationAppForm" className="btn">
                  Submit
                </button>
              </div>
              {error && <div className="submit-error red-text">{error}</div>}
            </form>
          )}
        </Formik>
      </>
    );
  }
}

const ValidationSchema = Yup.object().shape({
  supervisor_name: Yup.string().when('independent_advisor', {
    is: (independent_advisor) => !independent_advisor,
    then: () => Yup.string().required('Supervisor name is required.'),
  }),
  supervisor_email: Yup.string().when('independent_advisor', {
    is: (independent_advisor) => !independent_advisor,
    then: () => Yup.string().required('Supervisor email is required.'),
  }),
});
