/* 
Notes:
    - A List of helper functions to be reused with the site.
*/

// Makes a PDF and open it in a new window.
export const openBase64NewTab = (base64Pdf, title) => {
  var blob = base64toBlob(base64Pdf);
  // For IE users.
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, title);
  } else {
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, '_blank');
  }
};

export const months = [
  { label: 'Month', value: '' },
  { label: 'January', value: '01' },
  { label: 'Feburary', value: '02' },
  { label: 'March', value: '03' },
  { label: 'April', value: '04' },
  { label: 'May', value: '05' },
  { label: 'June', value: '06' },
  { label: 'July', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];

export const days = [
  { label: 'Day', value: '' },
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' },
  { label: '13', value: '13' },
  { label: '14', value: '14' },
  { label: '15', value: '15' },
  { label: '16', value: '16' },
  { label: '17', value: '17' },
  { label: '18', value: '18' },
  { label: '19', value: '19' },
  { label: '20', value: '20' },
  { label: '21', value: '21' },
  { label: '22', value: '22' },
  { label: '23', value: '23' },
  { label: '24', value: '24' },
  { label: '25', value: '25' },
  { label: '26', value: '26' },
  { label: '27', value: '27' },
  { label: '28', value: '28' },
  { label: '29', value: '29' },
  { label: '30', value: '30' },
  { label: '31', value: '31' },
];

export const getCurrentYears = (count, startYear) => {
  // Get the current date/time.
  const date = startYear ? new Date(`${startYear}/01/01`) : new Date();
  const years = [{ label: 'Year', value: '' }];
  for (let i = 0; i < count; i++) {
    years.push({ label: date.getFullYear(), value: date.getFullYear() });
    date.setFullYear(date.getFullYear() - 1);
  }
  return years;
};

export const usStates = [
  { label: 'State*', value: '' },
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'District of Columbia', value: 'DC' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

export const getBase64FromFile = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    // @TODO: Display an error to the user.
    console.error(`Error: ${error}`);
  };
};

// Converts Base65 data to Blob.
const base64toBlob = (base64Data) => {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: 'application/pdf' });
};

export const yesNoBool = (val) => {
  if (typeof val === 'string') {
    if (val.toLowerCase() === 'y') {
      return true;
    }
    if (val.toLowerCase() === 'n') {
      return false;
    }
  }
  return false;
};

export const getBadgeImage = (acadPlan) => {
  //  const xhr = new XMLHttpRequest();
  //  const path = `../../badges/${acadPlan?.toLowerCase()}.png`;
  //  console.log("checking for "+path);
  //  xhr.open('GET', path, false);
  //  xhr.send(); //On Acquia environment, xhr.status is 200 for EVERYTHING so it's maybe not actually correctly checking the files' existance.
  //  console.log(xhr.status);
  //  if (xhr.status === '404' || xhr.status === 404) {
  //      console.log(acadPlan+" status", xhr.status);
  //    return false;
  //  } else {
  //    return path;
  //  }
  // Brute Force Time!
  // List all badges we have an image for.
  const designationBadges = [
    'cap',
    'casl',
    'chfc',
    'chsnc',
    'clf',
    'clu',
    'fscp',
    'gfsp',
    'glmp',
    'msfp',
    'msfs',
    'msm',
    'phd',
    'ricp',
    'trust',
    'wmcp',
  ];
  if (designationBadges.includes(acadPlan?.toLowerCase())) {
    return `../../badges/${acadPlan?.toLowerCase()}.png`;
  } else {
    return `../../badges/blank.png`;
  }
};
/**
 * Found at Stack Overflow: https://stackoverflow.com/a/18650828/858561
 */
export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
