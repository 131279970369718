const ClearStorage = () => {
  const ClearAll = () => {
    sessionStorage.clear();
  };

  const ClearSingleItem = (name) => {
    sessionStorage.removeItem(name);
  };

  return {
    ClearAll,
    ClearSingleItem,
  };
};

export default ClearStorage;
