import Modal from '../subcomponents/Modal';

import { useIdleTimer } from 'react-idle-timer';
import { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { useOktaAuth } from '@okta/okta-react';

// 30 minutes
const timeout = 30 * 60 * 1000;
// 1 minute
const promptBeforeIdle = 60 * 1000;

export function Timeout() {
  const { oktaAuth } = useOktaAuth();
  const history = useHistory();
  const [, setCurrentState] = useState('Active');
  const [timeRemaining, setTimeRemaining] = useState(timeout);
  const [modalOpen, setModalOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  const onIdle = () => {
    setCurrentState('Idle');
    logout();
  };

  const onActive = () => {
    setCurrentState('Active');
    setModalOpen(false);
  };

  const onPrompt = () => {
    setCurrentState('Prompted');
    setModalOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    checkAuthState();
    // @TODO: Check if needed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
    // @TODO: Check if needed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStillHere = () => {
    activate();
  };

  const checkAuthState = async () => {
    await oktaAuth.isAuthenticated('none').then((status) => {
      setLoggedIn(status);
    });
  };

  const logout = async () => {
    //logout of okta if logged in
    if (loggedIn) {
      await oktaAuth.signOut().then(() => {
        oktaAuth.tokenManager.clear();
        localStorage.clear();
        sessionStorage.clear();
        history.push('/');
      });
    }
  };

  return (
    <>
      {modalOpen && (
        <Modal showLink={false} showNow={true} noClose={true}>
          <div className="container text-center">
            <h2>The session will time out in {timeRemaining} seconds.</h2>
            <p>The session will automatically end.</p>
            <p>
              <button className="btn center" onClick={handleStillHere}>
                Extend Session
              </button>
            </p>
            <p>If you are finished, click "Logout."</p>
            <p>
              <button className="btn secondary center" onClick={logout}>
                Logout
              </button>
            </p>
          </div>
        </Modal>
      )}
    </>
  );
}
