//import selectOptions from '../../../data/selectOptions.json';
//import { validateAgainstOptions } from '../../global/AnnualInfoLock';
import Accordion from '../../subcomponents/Accordion';
import CompanyAffiliationFormikField from '../../subcomponents/companyFields/CompanyAffiliationFormikField';
import CompanyCustomFormikFields from '../../subcomponents/companyFields/CompanyCustomFormikFields';
import { useQuery } from 'react-query';
import { Field } from 'formik';
import useTacfsService from '../../../utils/tacfs/useTacfsService';
import { useState, useEffect } from 'react';

export default function CompanyInfoForm({values, errors, touched, handleChange, handleBlur}) {
    const { load } = useTacfsService();
    const { isLoading: companiesLoading, data: companies } = useQuery(
    ['companies'],
    () => load('companies'),
  );
    const [errorInSection,setErrorInSection] = useState(false);
    
    /* highlight section title if any fields in this section have thrown an error! */
    useEffect(()=>{
        const fieldArray = [
            "crd",
            "company1",
            "company2",
            "userCompanyName",
            "companyName",
            "agent_id" ,
            "agent_role",
        ];
        const matchingErrors = Object.keys(errors).filter(element => fieldArray.includes(element));
//        console.log(matchingErrors);
        if(matchingErrors.length > 0){
            setErrorInSection(true);
        } else {
            setErrorInSection(false);
        }
        
    },[errors]);
    
    return (
     <Accordion id="test4" title=<h4 className={errorInSection?'error red-text':''}>Company Information</h4> closeTitle=<h4 className={errorInSection?'error red-text':''}>Company Information</h4> iconElementClose='title3' 
        iconElementOpen='title4' >
        <CompanyAffiliationFormikField
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            companies={companies}
            companiesLoading={companiesLoading}
            showLabels={true}
          />
          <CompanyCustomFormikFields
            values={values}
            handleChange={handleChange}
            showLabels={true}
          />
          <div className="column-layout">
            <div className="col">
          <div className="field">
                <label htmlFor="crd-field">CRD</label>
                <Field
                    type="text"
                    id="crd-field"
                    name="crd"
                    value={values.crd}
                    className={errors.crd ? 'error' : null}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.crd && (
                    <span className="red-text">{errors.crd}</span>
                  )}
            </div>  
           <div className="field">
                <label htmlFor="npn-field">NPN</label>
            <Field
                    type="text"
                    id="npn-field"
                    name="npn"
                    value={values.npn}
                    className={ errors.npn ? 'error' : null}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  { errors.npn && (
                    <span className="red-text">{errors.npn}</span>
                  )}
            </div>    
            </div>
            </div>
        </Accordion>
    );
}
