import React from 'react';

export const TacfsContext = React.createContext({
  tacfsContext: null,
  setContext: () => {},
});
export const PortalMessageContext = React.createContext({
  messages: null,
  lastUpdates:null,
  setMessageContext: () => {},
});
