import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useQuery, useMutation } from 'react-query';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';

import useTacfsService from '../../utils/tacfs/useTacfsService';
import ReadStorage from '../storage/ReadStorage';
import SetStorage from '../storage/SetStorage';

import { WhiteLogoImage, ReflexBlueLogoImage } from '../subcomponents/Icon';
import { NotificationBar } from '../subcomponents/NotificationBar';
import { SiteNotificationBanner } from '../subcomponents/SiteNotificationBanner';
import CompanyReportNav from '../subcomponents/CompanyReportNav';
import { ProfileLock } from './ProfileLock';
import { AnnualInfoLock } from './AnnualInfoLock';

import {
  getRecertStatusRevised,
  compliantStatuses,
} from '../../pages/DesignationsCertifications';

import ClearStorage from '../storage/ClearStorage';
import { PortalMessageContext } from '../global/Context';

import { OktaSSOLink } from '../../_okta/config/OktaSSO';

export function Header({ isMasquerading }) {
  return (
    <>
      <div className="">
        <a href="#mainContent" className="sr-only">
          Skip to content
        </a>
      </div>
      <NavFull isMasquerading={isMasquerading} />
    </>
  );
}

function NavFull({ isMasquerading }) {
  const oktaProfile = ReadStorage('oktaProfile');
  const [openMobileMenu, setMobileMenuState] = useState(false);
  const { authState, oktaAuth } = useOktaAuth();
  const [isAuth, setAuth] = useState(false);
  const [hideRecertLink, setHideRecertLink] = useState(false);
  const msgContext = useContext(PortalMessageContext);
  const msgCount = msgContext
    ? Array.isArray(msgContext.messageContext.messages)
      ? msgContext.messageContext.messages.reduce(
          (runningTotal, currentMessage) => {
            let val = 0;
            if (currentMessage?.status.toLowerCase() === 'unread') {
              val = 1;
            }
            return runningTotal + val;
          },
          0,
        )
      : 0
    : 0;
  const { ClearAll } = ClearStorage();
  const { load, save } = useTacfsService();

  const [showNotifBar, setShowNotifBar] = useState(ReadStorage('showNotifBar'));
  const { isLoading: studentCELoading, data: studentCE } = useQuery(
    ['studentCE'],
    () => load('studentCE'),
    {
      enabled: isAuth && oktaProfile?.profile?.studentID != null,
    },
  );
  const { /*isLoading: isDismissingBanner,*/ mutate: dismissBanner } =
    useMutation((values) => save('bannerDismissal', values));
  const { isLoading: loadingLastDismissal, data: lastDismissal } = useQuery(
    ['bannerDismissal'],
    () => load('bannerDismissal'),
    {
      enabled: isAuth && oktaProfile?.profile?.studentID != null,
    },
  );

  /* Show the banner or not based on recert status  or when the banner was last dismissed */
  useEffect(() => {
    let showBanner = false; // Assume we don't want to show the banner
    setHideRecertLink(false);
    if (studentCE && !studentCELoading && Object.keys(studentCE).length > 0) {
      const status = getRecertStatusRevised(studentCE);
      if (showNotifBar === undefined) {
        // Show/hide banner based on recert status
        showBanner = !compliantStatuses.includes(status);
      } else {
        showBanner = showNotifBar;
      }

      if (!loadingLastDismissal && lastDismissal) {
        // Hide banner if it was dismissed for the current recert year
        if (lastDismissal.year >= studentCE.year_display) {
          showBanner = false;
        }
      }
      // Hide the recert link for first year designees!
      if (status === 'firstyear' || status === 'noparticipation') {
        setHideRecertLink(true);
      }

      setShowNotifBar(showBanner);
      SetStorage('showNotifBar', showBanner);
    } else {
      //We do not have a studentCE object
      setHideRecertLink(true);
    }
    // @TODO: Check if needed!
    // Need to disable bc showNotifBar is not in the hook.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    studentCELoading,
    setShowNotifBar,
    studentCE,
    loadingLastDismissal,
    lastDismissal,
  ]);
  /* Show the banner or not based on when the banner was last dismissed */
  //  useEffect(() => {
  //    if (studentCE && !loadingLastDismissal && lastDismissal && Object.keys(studentCE).length > 0) {
  ////      if (showNotifBar === undefined) {
  //        if(lastDismissal.year >= studentCE.year_display ){
  //            setShowNotifBar(false);
  //            SetStorage('showNotifBar', false);
  //        }
  ////      }
  //    }
  //  }, [loadingLastDismissal, lastDismissal, studentCE, setShowNotifBar]);

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      setAuth(true);
    }
  }, [setAuth, authState]);

  const logOut = () => {
    setAuth(false);
    oktaAuth.signOut();
    //clear localStorage out entirely
    ClearAll();
  };

  const masqueradingLogout = () => {
    setAuth(false);
    //clear localStorage out entirely
    ClearAll();
    // Navigate back to Okta App.
    window.location.href = `${process.env.REACT_APP_ADMIN_TOOL_URL}?uid=${isMasquerading}`;
  };
  const dismissRecertBanner = () => {
    const data = {
      student_id: oktaProfile?.profile?.studentID,
      year: studentCE.year_display,
    };
    dismissBanner(data);
  };
  const toggleMobileMenu = (openMobileMenu) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setMobileMenuState(openMobileMenu);
  };

  const getNotification = {
    notificationMessage: "It's time to recertify!",
    notificationLink: '/designations-certifications/recertification',
    notificationLinkMessage: 'Get started here',
  };
  const { isLoading: isLoadingFlags, data: studentFlags } = useQuery(
    ['studentFlags'],
    () => load('studentFlags'),
    {
      enabled: isAuth && oktaProfile?.profile?.studentID != null,
    },
  );

  return (
    <header role="banner" className="nav-full">
      {isMasquerading && (
        <button
          className="btn masquerading-logout"
          onClick={masqueradingLogout}
        >
          Masquerade Logout
        </button>
      )}
      {isAuth && oktaProfile?.profile?.studentID != null && (
        <CompanyReportNav />
      )}
      <div className="notif-banner">
        <SiteNotificationBanner />
      </div>
      <NotificationBar
        notification={showNotifBar}
        notificationInfo={getNotification}
        dismissRecertBanner={dismissRecertBanner}
      />
      {isAuth && oktaProfile?.profile?.studentID != null && <ProfileLock />}
      {/* {@TODO: Why are we looking for so many different variables here?} */}
      {(process.env.REACT_APP_ENABLE_ANNUAL_REVIEW === 1 ||
        process.env.REACT_APP_ENABLE_ANNUAL_REVIEW === '1' ||
        process.env.ENABLE_ANNUAL_REVIEW === 1 ||
        process.env.ENABLE_ANNUAL_REVIEW === '1') &&
        isAuth &&
        oktaProfile?.profile?.studentID != null && <AnnualInfoLock />}
      <div className="container utility-nav text-sm portal">
        <div className="link-wrapper left">
          <a className="utility-nav-item" href="tel:866-310-8181">
            <img alt="Phone Icon" src="/icons/phone-icon.svg" />
            <span>866-310-8181</span>
          </a>
        </div>
        <div className="link-wrapper right">
          {isAuth && (
            <>
              <Link
                className="utility-nav-item message-center"
                to="/dashboard/messages"
              >
                <img alt="Bell Icon" src="/icons/bell-icon.svg" />
                <span className="msg-center-title">Message Center</span>
                {msgCount > 0 && <span className="count">{msgCount}</span>}
              </Link>
              <div className="utility-nav-item dropdown">
                <span>My Account</span>
                <ul>
                  <li>
                    <Link to="/profile">My Profile</Link>
                  </li>
                  {!isLoadingFlags &&
                    studentFlags?.khp_access === 'Y' &&
                    studentFlags?.khp_subscription_id && (
                      <li>
                        <Link to="/subscriptions">My Subscriptions</Link>
                      </li>
                    )}
                  <li className={`${hideRecertLink ? 'hidden' : ''}`}>
                    <Link to="/designations-certifications/recertification">
                      Recertification
                    </Link>
                  </li>
                  <li>
                    <Link to="/purchase-history">Purchase History</Link>
                  </li>
                  <li>
                    <Link to="/my-tax-forms">Tax Forms</Link>
                  </li>
                  <li>
                    <button onClick={logOut}>Logout</button>
                  </li>
                </ul>
              </div>
            </>
          )}
          <OktaSSOLink classes="utility-nav-item enroll-now-button">
            Enroll Now
          </OktaSSOLink>
        </div>
      </div>

      <div className="primary-nav">
        <div className="container flex">
          <Link className="logo" to="/">
            <WhiteLogoImage />
          </Link>
          {isAuth && (
            <div className="primary-nav-desktop">
              <Link className="primary-nav-item" to="/learning-journey">
                My Learning Journey
              </Link>
              <Link
                className="primary-nav-item"
                to="/designations-certifications"
              >
                My Designations & Certifications
              </Link>
              <Link className="primary-nav-item" to="/resources">
                Resource Library
              </Link>
            </div>
          )}

          <IconButton
            edge="start"
            color="inherit"
            aria-label="open mobile menu"
            onClick={toggleMobileMenu(true)}
            className="hamburger"
          >
            <MenuIcon />
          </IconButton>

          {/* Mobile Menu */}

          <Drawer
            className="mobile-nav-drawer"
            anchor="top"
            open={openMobileMenu}
            onClose={toggleMobileMenu(false)}
          >
            <div className="drawer-header flex">
              <ReflexBlueLogoImage />

              <IconButton
                onClick={toggleMobileMenu(false)}
                className="close-hamburger"
              >
                <MenuIcon />
              </IconButton>
            </div>

            <Divider />

            <div className="primary-nav-mobile">
              {isAuth && (
                <>
                  <div className="primary-nav-item">
                    <Link to="/learning-journey">My Learning Journey</Link>
                  </div>
                  <div className="primary-nav-item">
                    <Link to="/designations-certifications">
                      My Designations & Certifications
                    </Link>
                  </div>
                  <div className="primary-nav-item">
                    <Link to="/resources">Resource Library</Link>
                  </div>
                </>
              )}
              <div className="contact-item">
                <img alt="Chat Icon" src="/icons/chat-icon.svg" />
                <Link to="/">Chat With Us</Link>
              </div>
              <div className="contact-item">
                <img alt="Phone Icon" src="/icons/phone-icon.svg" />
                <a href="tel:866-310-8181">866-310-8181</a>
              </div>
            </div>
          </Drawer>
        </div>
      </div>
    </header>
  );
}
