/**
  This generates a URL for SSO into Enrollment.
  Pass Classes and any redirect parameters:
  <OktaSSOLink
    classes="utility-nav-item enroll-now-button"
  >
    Enroll Now
  </OktaSSOLink>

  if no parameters, omit or leave as an empty string ("")
  */

import ReadStorage from '../../components/storage/ReadStorage';

export function OktaSSOLink({
  classes,
  children,
  course,
  program,
  addons,
  promocode,
  company,
  enroll,
  rcrt,
  grd,
  nwm,
  sameTab,
}) {
  const sessionToken = ReadStorage('session');
  let urlParams = '';
  //testing, will be updated to use env in the future
  const enrollmentSSOUrl = `${process.env.REACT_APP_ECOMM_URL}utilAuth/loginSSO`;

  if (course !== undefined) {
    urlParams += `course=${course}|`;
  }

  if (program !== undefined) {
    urlParams += `program=${program.toLowerCase()}|`;
  }

  if (addons !== undefined) {
    urlParams += `addons=${addons}|`;
  }

  if (promocode !== undefined) {
    urlParams += `promocode=${promocode}|`;
  }

  if (company !== undefined) {
    urlParams += `company=${company}|`;
  }

  if (enroll !== undefined) {
    urlParams += `enroll=${enroll}|`;
  }

  if (rcrt !== undefined) {
    urlParams += `rcrt=${rcrt}`;
  }

  if (grd !== undefined) {
    urlParams += `grd=${grd}`;
  }

  if (nwm !== undefined) {
    urlParams += `nwm=${nwm}`;
  }

  const url = `${enrollmentSSOUrl}?session=${sessionToken}&params=${urlParams}`;

  let target;
  if (sameTab) {
    target = '_self';
  } else {
    target = '_blank';
  }

  return (
    <a href={url} className={classes} target={target} rel="noreferrer">
      {children}
    </a>
  );
}
