import { WhiteLogoImage } from '../subcomponents/Icon';

export function Maintenance() {
  document.title = 'Site Under Maintenance | The American College';
  return (
    <div className="maintenance">
      <header role="banner">
        <WhiteLogoImage />
      </header>
      <main className="maintenance-main" role="main">
        <h1 className="maintenance-title">Site under maintenance</h1>
        <p className="maintenance-text">
          The American College of Financial Services is currently under
          maintenance. We should be back shortly. Thank you for your patience.
        </p>
      </main>
    </div>
  );
}
