import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const { authState } = useOktaAuth();

  // If Auth is not set then we want to
  // return null.
  if (authState === null) return null;

  return (
    <Route {...rest}>
      {authState && !authState.isAuthenticated ? (
        <Redirect
          to={{
            pathname: `/`,
            search: `?destination=${location.pathname}`,
            state: { from: location },
          }}
        />
      ) : (
        <Component />
      )}
    </Route>
  );
};

export default PrivateRoute;
