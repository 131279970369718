import { useState, useEffect } from 'react';

import useProfileQuery from '../../utils/tacfs/useProfileQuery';
import calculateProfileCompletion from '../../utils/calculateProfileCompletion';
import ReadStorage from '../storage/ReadStorage';

// This is now disabled in that it doesn't return a blocking modal.
// The component still needs to be included in the header so that the completion
// percentage is calculated and it can be displayed on the profile.
// Commented out any unnecessary code to just calculate the percentage
export function ProfileLock() {
  //const { load } = useTacfsService();
  const oktaProfile = ReadStorage('oktaProfile');
  const { profileData } = useProfileQuery(oktaProfile);
  const [, setPercentComplete] = useState(100);
  //const location = useLocation();
  //const query = new URLSearchParams(location.search).get('dev');

  /*const { isLoading, data } = useQuery(['studentFlags'], () =>
    load('studentFlags'),
  );*/

  useEffect(() => {
    if (
      profileData.basic &&
      profileData.contact &&
      profileData.education &&
      profileData.company &&
      profileData.flags &&
      oktaProfile
    ) {
      setPercentComplete(calculateProfileCompletion(profileData));
    }
  }, [profileData, oktaProfile]);

  /*useEffect(() => {
    if (query === 'disableLock') {
      SetStorage('disableProfileLock', true);
    }
  }, []);*/

  /*return (
    <>
      {!ReadStorage('disableProfileLock') &&
        percentComplete < 100 &&
        location.pathname !== '/profile' &&
        location.pathname !==
          '/register/company/1000265/program/FSCP/course/TOC' &&
        isLoading === false &&
        data &&
        (data.reporting_user === 'N' || data.customer_type !== 'X') && (
          <ProfileLockModal />
        )}
    </>
  );*/
  return <></>;
}
// @TODO: This is never used. Remove next release.
// function ProfileLockModal() {
//   useEffect(() => {
//     if (typeof document !== 'undefined') {
//       document.querySelector('body').classList.remove('overflow-auto');
//       document.querySelector('body').classList.add('overflow-hidden');
//     }
//   }, []);

//   useEffect(() => {
//     return () => {
//       if (typeof document !== 'undefined') {
//         document.querySelector('body').classList.add('overflow-auto');
//         document.querySelector('body').classList.remove('overflow-hidden');
//       }
//     };
//   }, []);

//   return (
//     <div className="modal complete-modal active">
//       <div className="modalContent">
//         <div className="text-center container--sm">
//           <div className="title2">Complete Your Profile</div>
//           <p className="p2">
//             It looks like your profile is missing some key information. Please
//             visit your profile to finish setting up your account.
//           </p>
//           <Link to="/profile" className="btn center">
//             Complete Profile
//           </Link>
//         </div>
//       </div>
//     </div>
//   );
// }
