//import { useState } from 'react';
//import { Formik, Field } from 'formik';
import { FormikSelect, FormikText } from '../InputField';
import selectOptions from '../../../data/selectOptions.json';
//import mapping from '../../../data/selectValuesMapping.json';
import { CustomizedCompanies as cc } from '../../../data/Constants';
import { getCustomizedCompanyFields as getCustomFields } from '../../subcomponents/companyFields/CompanyCustomFields';
export function CompanyCustomFormikFields({
  values,
  handleChange,
  showLabels = false,
  layout = 'columns',
}) {
  const jobTitleOptions = selectOptions.jobTitle;
  const [employeeIDLabel, roleLabel] = getCustomFields({
    companyId: values.company1,
  });
  const selectedCompanyId = values.company2 ? values.company2 : values.company1;
  const customCompanyArray = [
    cc._STATEFARM,
    cc._EDWARDJONES,
    cc._JPM,
    cc._ALLSTATE,
    cc._EQUITABLE,
    cc._PRUDENTIAL,
    cc._THRIVENT,
    cc._WESTERNSOUTHERN,
    cc._KNIGHTSCOLUMBUS,
    cc._FARMERS,
  ];
  const customCompanyFieldRequired =
    customCompanyArray.includes(selectedCompanyId);
  const customCompanyFieldHidden =
    parseInt(cc._NORTHWESTERNMUTUAL) === parseInt(values.company1);

  if (layout === 'table') {
    return (
      <>
        {!customCompanyFieldHidden && (
          <div className="row">
            <div className="cell">
              {showLabels && !customCompanyFieldRequired && (
                <label htmlFor="agent_id">{employeeIDLabel}</label>
              )}
              {showLabels && customCompanyFieldRequired && (
                <label htmlFor="agent_id">
                  {employeeIDLabel} <span className="req-star">*</span>
                </label>
              )}
            </div>
            <div className="cell inline-field">
              <FormikText
                placeholder={
                  (customCompanyFieldRequired ? '* ' : '') + employeeIDLabel
                }
                type="text"
                id="agent_id"
                name="agent_id"
                value={values.agent_id}
                onChange={handleChange}
                maxLength={selectedCompanyId === cc._STATEFARM ? 6 : 60}
                required={customCompanyFieldRequired ? true : false}
              />
            </div>
          </div>
        )}
        {/* Removed Job Role/agent_role for Allstate WRP-1615 */}
        {[cc._STATEFARM].includes(selectedCompanyId) && (
          <div className="row">
            <div className="cell">
              {showLabels && (
                <label htmlFor="agent_role">
                  {roleLabel} <span className="req-star">*</span>
                </label>
              )}
            </div>
            <div className="cell inline-field">
              <FormikSelect
                placeholder={'* ' + roleLabel}
                id="agent_role"
                name="agent_role"
                value={values.agent_role}
                onChange={handleChange}
                required={true}
              >
                <option value="" disabled defaultValue>
                  * Select your {roleLabel}
                </option>
                {jobTitleOptions[selectedCompanyId].map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </FormikSelect>
            </div>
          </div>
        )}
        {[cc._ALLSTATE].includes(selectedCompanyId) && (
          <>
            {/* Hiding Region for Allstate WRP-1615 *}
            {/*<div className="row">
              <div className="cell">
                {showLabels && (
                  <label htmlFor="region">
                    Region <span className="req-star">*</span>
                  </label>
                )}
              </div>
              <div className="cell inline-field">
                <FormikText
                  placeholder="* Region"
                  type="text"
                  id="region"
                  name="region"
                  value={values.region}
                  onChange={handleChange}
                  maxLength="11"
                  required={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="cell">
                {showLabels && (
                  <label htmlFor="ntid">
                    NTID <span className="req-star">*</span>
                  </label>
                )}
              </div>
              <div className="cell inline-field">
                <FormikText
                  placeholder="* NTID"
                  type="text"
                  id="ntid"
                  name="ntid"
                  value={values.ntid}
                  onChange={handleChange}
                  maxLength="15"
                  required={true}
                />
              </div>
            </div>*/}
          </>
        )}
      </>
    );
  } else {
    return (
      <>
        {!customCompanyFieldHidden && (
          <div className="column-layout">
            <div className="col">
              <div className="field">
                {showLabels && !customCompanyFieldRequired && (
                  <label htmlFor="agent_id">{employeeIDLabel}</label>
                )}
                {showLabels && customCompanyFieldRequired && (
                  <label htmlFor="agent_id">
                    {employeeIDLabel} <span className="req-star">*</span>
                  </label>
                )}
                <FormikText
                  placeholder={
                    (customCompanyFieldRequired ? '* ' : '') + employeeIDLabel
                  }
                  type="text"
                  id="agent_id"
                  name="agent_id"
                  value={values.agent_id}
                  onChange={handleChange}
                  maxLength={selectedCompanyId === cc._STATEFARM ? 6 : 60}
                  required={customCompanyFieldRequired ? true : false}
                />
              </div>
            </div>
          </div>
        )}
        {/* Removed Job Role/agent_role for Allstate WRP-1615 */}
        {[cc._STATEFARM].includes(selectedCompanyId) && (
          <div className="column-layout">
            <div className="col">
              <div className="field">
                {showLabels && (
                  <label htmlFor="agent_role">
                    {roleLabel} <span className="req-star">*</span>
                  </label>
                )}
                <FormikSelect
                  placeholder={'* ' + roleLabel}
                  id="agent_role"
                  name="agent_role"
                  value={values.agent_role}
                  onChange={handleChange}
                  required={true}
                >
                  <option value="" disabled defaultValue>
                    * Select your {roleLabel}
                  </option>
                  {jobTitleOptions[selectedCompanyId].map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </FormikSelect>
              </div>
            </div>
          </div>
        )}
        {[cc._ALLSTATE].includes(selectedCompanyId) && (
          <>
            {/* Hiding Region for Allstate WRP-1615 *}
            {/*<div className="column-layout">
              <div className="col">
                <div className="field">
                  {showLabels && (
                    <label htmlFor="region">
                      Region <span className="req-star">*</span>
                    </label>
                  )}
                  <FormikText
                    placeholder="* Region"
                    type="text"
                    id="region"
                    name="region"
                    value={values.region}
                    onChange={handleChange}
                    maxLength="11"
                    required={true}
                  />
                </div>
              </div>
            </div>
            <div className="column-layout">
              <div className="col">
                <div className="field">
                  {showLabels && (
                    <label htmlFor="ntid">
                      NTID <span className="req-star">*</span>
                    </label>
                  )}
                  <FormikText
                    placeholder="* NTID"
                    type="text"
                    id="ntid"
                    name="ntid"
                    value={values.ntid}
                    onChange={handleChange}
                    maxLength="15"
                    required={true}
                  />
                </div>
              </div>
            </div>
             */}
          </>
        )}
      </>
    );
  }
}
export default CompanyCustomFormikFields;
