import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import {
  FacebookLogo,
  YouTubeLogo,
  TwitterLogo,
  LinkedInLogo,
} from '../subcomponents/Icon';

export function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="bottom-row flex-column">
          <div className="socials flex">
            <div>
              <Link
                to={{
                  pathname: 'https://www.facebook.com/theamericancollege/',
                }}
                target="_blank"
              >
                <FacebookLogo />
              </Link>
            </div>
            <div>
              <Link
                to={{ pathname: 'https://twitter.com/TheAmerCol' }}
                target="_blank"
              >
                <TwitterLogo />
              </Link>
            </div>
            <div>
              <Link
                to={{
                  pathname:
                    'https://www.linkedin.com/school/the-american-college-cp',
                }}
                target="_blank"
              >
                <LinkedInLogo />
              </Link>
            </div>
            <div>
              <Link
                to={{ pathname: 'https://www.youtube.com/user/TheAmerCol1927' }}
                target="_blank"
              >
                <YouTubeLogo />
              </Link>
            </div>
          </div>

          <div className="links-container flex-column">
            <ul>
              <li>
                <Link to={{ pathname: `${process.env.REACT_APP_MARKETING_URL}privacy-policy` }} target="_blank">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to={{ pathname: `${process.env.REACT_APP_MARKETING_URL}terms-of-use` }} target="_blank" >
                  Terms of Use
                </Link>
              </li>
              <li>
                <Link to={{ pathname: `${process.env.REACT_APP_MARKETING_URL}refund-policy` }} target="_blank">
                  Refund Policy
                </Link>
              </li>
              <li>
                <Link to="/cookie-declaration">
                  Cookie Declaration
                </Link>
              </li>
              <li>&copy; {dayjs().format("YYYY")} The American College of Financial Services</li>
              <li>Web Design by Push10 Branding Agency</li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
// TODO: commenting out for now so we dont get a warning.
// function FooterLinkHeader(props) {
//   return (
//     <div className="footer-link-header">
//       <Link to={props.to}>{props.title}</Link>
//     </div>
//   );
// }

// function FooterLink(props) {
//   return (
//     <div className="footer-link">
//       <Link to={props.to}>{props.title}</Link>
//     </div>
//   );
// }

// function LinkDivider(props) {
//   return (
//     <Divider
//       className="border-black mx-2"
//       orientation="vertical"
//       flexItem={true}
//     />
//   );
// }
