/* 
Notes:
    - fucntions must be called in a try/catch statement to catch errors.
*/
import useRequest from '../useRequest';
import ReadStorage from '../../components/storage/ReadStorage';

const useTacfsService = () => {
  // Get the request object
  const [request] = useRequest();

  // Load the defined request and return the data to the user.
  const load = async (subsection, params) => {
    // Pull in the user data from local storage.
    const tacfsProfile = ReadStorage('oktaProfile');
    try {
      if (params == null || params.studentID == null) {
        // If we dont pass in the student ID, we want to pull the ID from the storage
        if (tacfsProfile != null && tacfsProfile.profile.studentID != null) {
          // Add the student ID to the params.
          if (params) {
            params.studentID = tacfsProfile.profile.studentID;
          } else {
            params = { studentID: tacfsProfile.profile.studentID };
          }
        }
      }
      // if the endpoint is not defined then we throw an error
      if (loadSubsection(subsection, params) === false)
        throw new Error(`${subsection}: Request not defined!`);
      if (loadSubsection(subsection, params).get == null)
        throw new Error(`${subsection}: Get not defined!`);
      // Define call options.
      const options = {
        url: loadSubsection(subsection, params).get,
        method: 'GET',
        baseUrl: loadSubsection(subsection, params).baseUrl,
      };
      // Load the request with options.
      const response = await request(options);
      return response;
    } catch (error) {
      // Reject the promise so
      // react-query knows it falied
      return Promise.reject(error);
    }
  };

  // Save the defined request and return the data to the use if applicable.
  const save = async (subsection, params) => {
    // Pull in the user data from local storage.
    const tacfsProfile = ReadStorage('oktaProfile');
    try {
      if (params == null || params.studentID == null) {
        // If we dont pass in the student ID, we want to pull the ID from the storage
        if (tacfsProfile != null && tacfsProfile.profile.studentID != null) {
          // Add the student ID to the params.
          if (params) {
            params.studentID = tacfsProfile.profile.studentID;
          } else {
            params = { studentID: tacfsProfile.profile.studentID };
          }
        }
      }
      // if the endpoint is not defined then we throw an error
      if (loadSubsection(subsection, params) === false)
        throw new Error(`${subsection}: Request not defined!`);
      if (
        loadSubsection(subsection, params).post == null &&
        loadSubsection(subsection, params).put == null
      )
        throw new Error(`${subsection}: Post/Put not defined!`);
      const method =
        loadSubsection(subsection, params).post == null ? 'PUT' : 'POST';
      // Define call options.
      const options = {
        url:
          loadSubsection(subsection, params).post ??
          loadSubsection(subsection, params).put,
        method: method,
        data: schema(subsection, params),
        baseUrl: loadSubsection(subsection, params).baseUrl,
      };

      // Save the request with options.
      const response = await request(options);
      // Return true if the call is
      // successful.
      return response;
    } catch (error) {
      // Reject the promise so
      // react-query knows it falied
      return Promise.reject(error);
    }
  };

  // TODO: Pull this into its own file for cleaniness
  // Gather the correct URL based on subsection.
  function loadSubsection(subsection, params) {
    switch (subsection) {
      case 'studentInitial':
        return {
          get: `/TAC_EDU/student/${params.studentID}/getStudentInfoInitial`,
        };
      case 'studentBasic':
        return {
          get: `/TAC_EDU/student/${params.studentID}/getStudentBasicInfo`,
          put: `/TAC_EDU/student/${params.studentID}/DOBGender`,
        };
      case 'ssn':
        return {
          post: `/TAC_EDU/student/${params.studentID}/ssn`,
        };
      case 'ssnOptOut':
        return {
          post: `/TAC_EDU/student/${params.studentID}/ssnOptOut`,
        };
      case 'studentContact':
        return {
          get: `/TAC_EDU/student/${params.studentID}/getStudentContactInformation`,
        };
      case 'studentContactall':
        return {
          put: `/TAC_EDU/student/accountInformation`,
        };
      case 'studentContactUpdate':
        return {
          put: `/TAC_EDU/student/accountInformation`,
        };
      case 'studentBusiness':
        return {
          put: `/TAC_EDU/student/${params.studentID}/businessInfo`,
        };
      case 'studentResidence':
        return {
          put: `/TAC_EDU/student/${params.studentID}/residenceInfo`,
        };
      case 'studentCompany':
        return {
          get: `/TAC_EDU/student/${params.studentID}/getStudentCompanyInfo`,
          put: `/TAC_EDU/student/${params.studentID}/companyInfo`,
        };
      case 'studentEducation':
        return {
          get: `/TAC_EDU/student/${params.studentID}/getStudentEducationalInfo`,
          post: `/TAC_EDU/student/${params.studentID}/educationInfo`,
        };
      case 'studentCE':
        return {
          get: `/TAC_EDU/student/${params?.studentID}/getEligibility`,
          baseUrl: '/tacapi/ws/rest',
        };
      case 'studentInfoReview':
        return {
          get: `/TAC_EDU/student/${params.studentID}/infoReview`,
          post: `/TAC_EDU/student/infoReview`,
        };
      case 'studentFlags':
        return {
          get: `TAC_EDU/student/${params?.studentID}/getStudentAdditionalInformation`,
          baseUrl: '/tacapi/ws/rest',
        };
      case 'companyReportingFlags':
        return {
          get: `TAC_EDU/student/${params.studentID}/getReportingAccessInfo`,
        };
      case 'checkExistingStudent':
        return {
          post: `/check-existing-account`,
          baseUrl: '/api',
        };
      case 'findStudent':
        return {
          get: `/TAC_EDU/student/search?id=${params.studentID}&ssn=${params.ssn}&resEmail=${params.email}&busEmail=${params.email}`,
        };
      case 'courseHistory':
        return {
          get: `/TAC_EDU/student/${params.studentID}/courseHistory`,
        };
      case 'transcriptHistory':
        return {
          get: `/TAC_EDU/student/${params.studentID}/transcriptHistory`,
        };
      case 'gradeReportList':
        return {
          get: `/TAC_EDU/student/${params.studentID}/gradeReportListN`,
        };
      case 'gradeReport':
        return {
          get: `/TAC_EDU/student/${params.studentID}/gradeReport?term=${params.term}&classNumber=${params.classNumber}`,
        };
      case 'taxYears':
        return {
          get: `/TAC_EDU/student/${params.studentID}/tuitionStatementYears`,
        };
      case 'transcript':
        return {
          get: `/TAC_EDU/student/${params.studentID}/transcript`,
        };
      case 'taxForm':
        return {
          get: `/TAC_EDU/student/${params.studentID}/1098T?year=${params.year}`,
        };
      case 'oldTaxForm':
        return {
          get: `/TAC_EDU/student/${params.studentID}/tuitionStatement?year=${params.year}`,
        };
      case 'advisorData':
        return {
          get: `TAC_EDU/advisor/${params.studentID}`,
          put: `TAC_EDU/advisor/profile`,
        };
      case 'proctor':
        return {
          get: `/TAC_EDU/student/proctorForm`,
          post: `/TAC_EDU/student/registerProctor`,
        };
      case 'proctorAcknowledgement':
        return {
          post: `/proctor-acknowledgement`,
          baseUrl: '/api',
        };
      case 'validateRetrieveProctor':
        return {
          get: `/validate-and-retrive-proctor?student_id=${params.studentID}&term=${params.term}&class_number=${params.classNumber}&registration_date=${params.registrationDate}&registration_num=${params.registrationNum}`,
          baseUrl: '/api',
        };
      case 'transferOfCredit':
        return {
          post: `TAC_EDU/student/${params.studentID}/transferOfCredit`,
        };
      case 'designationReport':
        return {
          post: `TAC_EDU/reporting/designation`,
        };
      case 'enrollmentReport':
        return {
          post: `TAC_EDU/reporting/enrollment`,
        };
      case 'expiredReport':
        return {
          post: `TAC_EDU/reporting/expiredExam`,
        };
      case 'passListReport':
        return {
          post: `TAC_EDU/reporting/passList`,
        };
      case 'failListReport':
        return {
          post: `TAC_EDU/reporting/failList`,
        };
      case 'matriculationReport':
        return {
          post: `TAC_EDU/reporting/matriculation`,
        };
      case 'statefarmReport':
        return {
          post: `TAC_EDU/reporting/statefarmSearch`,
        };
      case 'statefarmDetailReport':
        return {
          post: `TAC_EDU/reporting/statefarmRecord`,
        };
      case 'learnMore':
        return {
          post: `/TAC_EDU/student/${params.studentID}/prpInfo`,
        };
      case 'promoCodeVerify':
        return {
          post: '/TAC_EDU/enrollment/promoCodes/verify',
        };
      case 'ethicsQuestionnaire':
        return {
          post: `/TAC_EDU/student/${params.studentID}/PRQuestionnaire`,
        };
      case 'ceAttestation':
        return {
          post: `/TAC_EDU/student/${params.studentID}/selfReportCE`,
        };
      case 'externalCE':
        return {
          get: `/TAC_EDU/student/${params.studentID}/externalCECredits?beginDate=${params.beginDate}`,
          post: `/TAC_EDU/student/${params.studentID}/externalCECredits`,
        };
      case 'ceDetail':
        return {
          get: `/TAC_EDU/student/${params.studentID}/CEDetail?beginDate=${params.beginDate}`,
        };
      // Drupal Marketing Site Endpoints
      case 'getPortalMessages':
        return {
          get: `/portal-message/messages/${params.studentID}`,
          baseUrl: '/tacdrupal/v1/',
        };
      case 'acknowledgePortalMessage':
        return {
          post: `/portal-message/acknowledgement`,
          baseUrl: '/tacdrupal/v1/',
        };

      case 'savedResources':
        let srQueryParams = [];

        if (params?.offset) {
          srQueryParams.push(`offset=${params.offset}`);
        }
        if (params?.limit) {
          srQueryParams.push(`limit=${params.limit}`);
        }
        if (params?.filterBy) {
          srQueryParams.push(`filterBy=${params.filterBy}`);
        }
        if (params?.format) {
          srQueryParams.push(`format=${params.format}`);
        }
        if (params?.textSearch) {
          srQueryParams.push(`textSearch=${params.textSearch.trim()}`);
        }
        return {
          get: `/saved-resources/${params.oktaID}?${srQueryParams.join('&')}`,
          post: `/saved-resources`,
          baseUrl: '/tacdrupal/v1/',
        };
      case 'allResources':
        const url = `/resources`;
        let queryParams = [];

        if (params?.classes) {
          queryParams.push(`classes=${JSON.stringify(params.classes)}`);
        }
        if (params?.offset) {
          queryParams.push(`offset=${params.offset}`);
        }
        if (params?.limit) {
          queryParams.push(`limit=${params.limit}`);
        }
        if (params?.isAlumni) {
          queryParams.push(`isAlumni=${params.isAlumni}`);
        }
        if (params?.filterBy) {
          queryParams.push(`filterBy=${params.filterBy}`);
        }
        if (params?.format) {
          queryParams.push(`format=${params.format}`);
        }
        if (params?.textSearch) {
          queryParams.push(`textSearch=${params.textSearch.trim()}`);
        }

        return {
          //          get: `/resources?classes=${JSON.stringify(params.classes)}&offset=${params.offset}&limit=${params.limit}&isAlumni=${params.isAlumni}`,
          get: `${url}?${queryParams.join('&')}`,
          baseUrl: '/tacdrupal/v1/',
        };
      case 'notificationFeed':
        return {
          get: `/site-notification-feed`,
          baseUrl: '/tacdrupal/v1/',
        };
      case 'bannerDismissal':
        return {
          get: `/dismissed-banner/${params.studentID}`,
          post: `/dismissed-banner`,
          baseUrl: '/tacdrupal/v1/',
        };
      case 'awardLetter':
        return {
          get: `TAC_EDU/student/${params.studentID}/awardLetter/${params.designation}`,
        };
      case 'completerKitInfo':
        return {
          get: `TAC_EDU/student/${params.studentID}/completerKitInfo`,
        };
      case 'designationApp':
        return {
          get: `TAC_EDU/student/${params.studentID}/designationApp/${params.designation}`,
          post: `TAC_EDU/student/designationApplication`,
        };
      case 'designationAppStatus':
        return {
          get: `TAC_EDU/student/${params.studentID}/designationAppStatus`,
          baseUrl: '/tacapi/ws/rest',
        };
      case 'designationAppStatusSupervisor':
        return {
          get: `/get-designation-application-status/${params.studentID}?designation=${params.designation}&applid=${params.applid}`,
          baseUrl: '/api',
        };
      case 'designationAppSupervisor':
        return {
          post: `TAC_EDU/student/designationAppSupervisor`,
        };
      case 'confirmationLetter':
        return {
          get: `TAC_EDU/student/${params.studentID}/PRConfirmationLetter?beginDate=${params.beginDate}&yearDisplay=${params.yearDisplay}`,
        };
      case 'confirmationLetters':
        return {
          get: `TAC_EDU/student/${params.studentID}/allConfirmationLetters`,
        };
      case 'companies':
        return {
          get: `/TAC_EDU/enrollment/companies`,
          baseUrl: '/tacapi/ws/rest',
        };
      case 'purchaseHistory':
        return {
          get: `/TAC_EDU/student/${params.studentID}/purchaseHistory`,
        };
      case 'receipt':
        return {
          get: `/TAC_EDU/student/${params.studentID}/receipt?postedDate=${params.postedDate}`,
        };
      case 'verifyExistingStudent':
        return {
          get: `/account-lookup-and-verification?studentId=${params.studentID}&email=${params.email}`,
          baseUrl: '/api',
        };
      case 'lookUpOktaRecord':
        return {
          get: `/account-lookup?studentId=${params.studentID}&email=${params.email}&username=${params.username}`,
          baseUrl: '/api',
        };
      case 'resetPwdByEmail':
        return {
          post: `/resetPwdByEmail`,
          baseUrl: '/api',
        };
      case 'provisionOktaAccount':
        return {
          post: `/provision-okta-account`,
          baseUrl: '/api',
        };
      case 'unifyLookup':
        return {
          get: `/validate-web-token/${params.webToken}`,
          baseUrl: '/api',
        };
      case 'getUserData':
        let getUrl;
        if (ReadStorage('masqueradingUser') !== undefined) {
          getUrl = `/oktaAdminUser/${ReadStorage('masqueradingUser')}`;
        } else {
          getUrl = `/oktaUser`;
        }
        return {
          get: getUrl,
          baseUrl: '/api',
        };
      case 'unifyAlumniStatus':
        return {
          put: `/oktaUser/unifyAlumniStatus`,
          baseUrl: '/api',
        };
      case 'updatePrimaryEmail':
        return {
          post: `/oktaUser/updatePrimaryEmail`,
          baseUrl: '/api',
        };
      case 'updateSecondaryEmail':
        return {
          post: `/oktaUser/updateSecondaryEmail`,
          baseUrl: '/api',
        };
      case 'updateMobile':
        return {
          post: `/oktaUser/updateMobile`,
          baseUrl: '/api',
        };
      case 'verifyOTP':
        return {
          post: `/oktaUser/verifyOTP`,
          baseUrl: '/api',
        };
      case 'resendOTP':
        return {
          post: `/oktaUser/resendOTP`,
          baseUrl: '/api',
        };
      case 'listFactors':
        return {
          get: `/oktaUser/listFactors`,
          baseUrl: '/api',
        };
      case 'gradApplication':
        return {
          post: `/graduate-application`,
          baseUrl: '/api',
        };
      case 'nylStudent':
        return {
          post: `/TAC_EDU/nyl/student`,
          baseUrl: '/tacapi/ws/rest',
        };
      case 'nylStudentLink':
        return {
          post: `/nyl-account-link/${params.studentID}`,
          baseUrl: '/api',
        };
      case 'createNylStudent':
        return {
          post: `/create-nyl-student`,
          baseUrl: '/api',
        };
      case 'createStudent':
        return {
          post: `/create-student-account`,
          baseUrl: '/api',
        };
      case 'lifterHash':
        return {
          get: `/lifter/sso/${params.studentID}`,
          baseUrl: '/api',
        };
      case 'khPlusHash':
        return {
          post: `TAC_EDU/lifter/${params.studentID}/enrollPlus`,
          baseUrl: '/tacapi/ws/rest',
        };
      case 'lmsHashV4':
        return {
          get: `TAC_EDU/knowledge/login/${params.studentID}/v4`,
          baseUrl: '/tacapi/ws/rest',
        };
      case 'lmsHashV1':
        return {
          get: `TAC_EDU/knowledge/login/${params.studentID}/v1`,
          baseUrl: '/tacapi/ws/rest',
        };
      case 'changePasswordFlow':
        return {
          post: `/oktaUser/credentials/change_password`,
          baseUrl: '/api',
        };
      case 'changeSecurityQuestion':
        return {
          post: `/oktaUser/credentials/change_recovery_question`,
          baseUrl: '/api',
        };
      case 'verifyNwm':
        return {
          post: `/oktaUser/nwm_verify_and_update`,
          baseUrl: '/api',
        };
      case 'couplrOptIn':
        return {
          post: `TAC_EDU/student/couplrOptIn`,
        };
      case 'vpnCheck':
        return {
          get: `vpn-check`,
          baseUrl: '/api',
        };
      case 'checkMaintenanceMode':
        return {
          get: `maint-enabled`,
          baseUrl: '/api',
        };
      default:
        return false;
    }
  }

  // TODO: Pull this into its own file for cleaniness
  // Gather the data to be posted to boomi.
  function schema(subsection, data) {
    switch (subsection) {
      case 'studentBasic':
        return {
          date_of_birth: data.date_of_birth,
          gender: data.gender,
          military_status: data.military_status,
          cit_country: data.cit_country,
          cit_status: data.cit_status,
          ethnicity: data.ethnicity,
          hispanic: data.hispanic,
          country: data.country,
        };

      case 'ssn':
        return data.ssn;

      case 'ssnOptOut':
        return {};

      case 'studentContactall':
        return data;

      case 'checkExistingStudent':
        return {
          email: data.email,
          ssn: data.ssn,
        };

      case 'studentContactUpdate':
        return {
          business_information: {
            email: data.business.email,
            address1: data.business.address1,
            address2: data.business.address2,
            address3: '',
            city: data.business.city,
            state: data.business.state,
            postal: data.business.postal,
            country: data.business.country,
            phone: data.business.phone,
            phone_type: data.business.phone_type,
          },
          home_information: {
            email: data.home.email,
            address1: data.home.address1,
            address2: data.home.address2,
            address3: '',
            city: data.home.city,
            state: data.home.state,
            postal: data.home.postal,
            country: data.home.country,
            phone: data.home.phone,
            phone_type: data.home.phone_type,
          },
          primary_address: data.primary_address,
          primary_phone: data.primary_phone,
          primary_email: data.primary_email,
          student_id: data.studentID,
        };

      case 'studentBusiness':
        return {
          business_information: {
            email: data.email,
            address1: data.address1,
            address2: data.address2,
            address3: '',
            city: data.city,
            state: data.state,
            postal: data.postal,
            country: data.country,
            phone: data.phone,
            phone_type: data.phone_type,
            primary_address: data.primary_address,
            primary_phone: data.primary_phone,
            primary_email: data.primary_email,
          },
        };

      case 'studentResidence':
        return {
          home_information: {
            email: data.email,
            address1: data.address1,
            address2: data.address2,
            address3: '',
            city: data.city,
            state: data.state,
            postal: data.postal,
            country: data.country,
            phone: data.phone,
            phone_type: data.phone_type,
            primary_address: data.primary_address,
            primary_phone: data.primary_phone,
            primary_email: data.primary_email,
          },
        };

      case 'studentCompany':
        return {
          company_id: data.company_id,
          company_name: data.company_name,
          independent: data.independent,
          agent_id: data.agent_id,
          role: data.role,
          agency_name: data.agency_name,
          region: data.region,
          ntid: data.ntid,
          lsp_cd: data.lsp_cd,
          market: data.market,
          territory: data.territory,
          crd: data.crd,
          npn: data.npn,
        };

      case 'studentEducation':
        return {
          number_of_years: data.number_of_years,
          job_focus: data.job_focus,
          hs_diploma: data.hs_diploma,
          some_college: data.some_college,
          associate_degree: data.associate_degree,
          bachelor_degree: data.bachelor_degree,
          master_degree: data.master_degree,
          prof_degree: data.prof_degree,
          doctorate_degree: data.doctorate_degree,
          other_education: data.other_education,
          prev_online_education: data.prev_online_education,
          success: data.success,
          self_directed: data.self_directed,
          high_structure: data.high_structure,
          support_service: data.support_service,
          trad_education_prog: data.trad_education_prog,
          learning_style: data.learning_style,
          income_level: data.income_level,
        };
      case 'studentInfoReview':
        return {
          student_id: data.studentID,
        };
      case 'proctor':
        return {
          student_id: data.studentID,
          term: data.term,
          class_number: data.classNumber,
          license_state: data.licensestate,
          last_name: data.lastname,
          first_name: data.firstname,
          middle_name: data.middlename,
          address1: data.address1,
          address2: data.address2,
          address3: '',
          city: data.city,
          state: data.state,
          postal: data.zip,
          phone: data.phone,
          ext: data.phoneext,
          email: data.email,
          confirm_email: data.email,
          hash_signature: '', // Note Needed
        };

      case 'validateProctor':
      case 'retrieveProctor':
        return {
          student_id: data.studentID,
          term: data.term,
          class_number: data.classNumber,
          registration_date: data.registrationDate,
          registration_number: data.registrationNum,
        };

      case 'proctorAcknowledgement':
        return {
          student_id: data.studentID,
          term: data.term,
          class_number: data.classNumber,
          registration_date: data.registrationDate,
          registration_number: data.registrationNum,
          action: data.acknowledgement,
          esign: data.signature,
        };

      case 'transferOfCredit':
        const tocData = {
          email_addr: data.email,
          phone: data.phone,
        };
        if (data.certificationLetter) {
          tocData.file_location = data.certificationLetter;
        }
        if (data.syllabiFiles) {
          // Add all the form files together.
          tocData.file_location = data.syllabiFiles.concat(data.addFiles);
        }
        if (data.whenCompleted) tocData.cfp_complete = data.whenCompleted;
        if (data.clu || data.chfc) {
          tocData.cfp = [];
          if (data.clu) {
            tocData.cfp.push({
              acad_plan: 'clu',
              tac_course: data.clu,
            });
          }
          if (data.chfc) {
            tocData.cfp.push({
              acad_plan: 'chfc',
              tac_course: data.chfc,
            });
          }
        }
        if (data.degrees) {
          tocData.degrees = [];
          data.degrees.map((data) =>
            tocData.degrees.push({
              school: data.name,
              year_from: data.yearFrom,
              year_to: data.yearTo,
              degree_name: data.certificate,
              award_date: `${data.awardMonth}/${data.awardDay}/${data.awardYear}`,
            }),
          );
        }
        if (data.courses) {
          tocData.courses = [];
          data.courses.map((data) =>
            tocData.courses.push({
              course_name: data.title,
              school: data.school,
              completed_date: `${data.completedMonth}/${data.completedDay}/${data.completedYear}`,
              grade: data.grade,
              credit_hours: data.hours,
              tac_course: [data.hsCourse],
            }),
          );
        }
        return tocData;

      case 'designationReport':
      case 'enrollmentReport':
      case 'expiredReport':
      case 'passListReport':
      case 'failListReport':
      case 'matriculationReport':
      case 'statefarmReport':
      case 'statefarmDetailReport':
        return {
          report_emp_id: data.studentID,
          emp_id: data.emplId,
          first_name: data.firstName,
          last_name: data.lastName,
          date_from:
            data.time && data.time.startDate
              ? data.time.startDate.toLocaleDateString()
              : '',
          date_to:
            data.time && data.time.startDate
              ? data.time.endDate.toLocaleDateString()
              : '',
          tac_arid: data.arid,
          agent_id: data.agentId,
        };

      case 'acknowledgePortalMessage':
        return {
          student_id: data.studentID,
          message_id: data.message_id,
          ackType: data.ackType,
        };

      case 'designationApp':
        return { ...data.data };

      case 'designationAppSupervisor':
        return {
          emplid: data.studentID,
          applid: data.applId,
          acad_plan: data.designation.toUpperCase(),
          supervisor_name: data.fullName,
          title: data.title,
          organization: data.organization,
          phone: data.phone,
          response: data.response,
        };
      case 'advisorData':
        return {
          student_id: data.studentID,
          first_name: data.first_name,
          last_name: data.last_name,
          title: data.title,
          state: data.state,
          asset_range: data.asset_range,
          payment_method: data.payment_method,
          opt_in: data.opt_in,
          profile_photo: data.profile_photo,
          address_type: data.address_type,
          address1: data.address1,
          address2: data.address2,
          city: data.city,
          postal: data.postal,
          phone: data.phone,
          email_address: data.email_address,
          retired: data.retired,
          company_id: data.company_id,
          company_name: data.company_name,
          broker_id: data.broker_id,
          linkedin: data.linkedin,
          facebook: data.facebook,
          website: data.website,
          fiduciary_advisor: data.fiduciary_advisor,
          advisor_expertise: data.advisor_expertise,
          advisor_language: data.advisor_language,
          files: data.files,
        };
      case 'savedResources':
        return {
          student_id: data.studentID,
          id: data.oktaID,
          nid: data.nid,
        };
      case 'bannerDismissal':
        return {
          student_id: data.studentID,
          year: data.year,
        };
      case 'verifyStudent':
        return {
          emplid: data.studentID,
          email_address: data.email,
        };

      case 'provisionOktaAccount':
        return {
          webToken: data.webToken,
          login: data.email,
          mobilePhone: data.mobilePhone,
          credentials: {
            password: {
              value: data.pass,
            },
            recovery_question: {
              question: data.securityQuestion,
              answer: data.securityAnswer,
            },
          },
        };
      case 'learnMore':
        return {
          contactMethod: data.contactMethod,
          other: data.other,
        };
      case 'promoCodeVerify':
        return {
          student_id: data.studentID,
          promo_code: data.promo_code,
          company_id: data.company_id,
        };
      case 'ethicsQuestionnaire':
        return {
          begin_date: data.begin_date,
          year_display: data.year_display,
          datetime_added: data.datetime_added,
          approval_date: data.approval_date,
          promo_code: data.promo_code,
          company_id: data.company_id,
          q1_yn: data.q1_yn,
          q2_yn: data.q2_yn,
          q2_comments: data.q2_comments,
          q3_yn: data.q3_yn,
          q3_comments: data.q3_comments,
          q4_yn: data.q4_yn,
          q4_comments: data.q4_comments,
          q5_yn: data.q5_yn,
          q5_comments: data.q5_comments,
          q6_yn: data.q6_yn,
          q6_comments: data.q6_comments,
          q7_yn: data.q7_yn,
          q7_comments: data.q7_comments,
          q8_yn: data.q8_yn,
          q8_comments: data.q8_comments,
        };
      case 'ceAttestation':
        return {
          initial_entry: data.initialEntry,
        };
      case 'externalCE':
        return {
          initial_entry: data.initial_entry,
          period_begin_date: data.period_begin_date,
          year_display: data.year_display,
          date_earned: data.date_earned,
          institution: data.institution,
          catalog_number: data.catalog_number,
          seq_number: data.seq_number,
          course_title: data.course_title,
          ce_crd_earned: data.ce_crd_earned,
          eth_crd_earned: data.eth_crd_earned,
          category_number: data.category_number,
          delete_flag: data.delete_flag,
        };
      case 'gradApplication':
        return {
          designation: data.plan,
          last_name: data.lastName,
          first_name: data.firstName,
          middle_name: '',
          prefix: data.prefix,
          country: 'USA',
          files: data.files,
          concentration:
            data.formPlan.toLowerCase() === 'msfp'
              ? data.concentrationLabel
              : 'Not Applicable',
          cohort:
            data.formPlan.toLowerCase() === 'msm'
              ? data.cohortLabel
              : 'Not Applicable',
          student_id: data.student_id,
          language: data.language,
          citizen: data.citizen === 'Y' ? 'Yes' : 'No',
          bachelors: data.bachelors === 'Y' ? 'Yes' : 'No',
          home_information: {
            address1: data.address,
            address2: data.address2,
            address3: '',
            city: data.city,
            state: data.state,
            postal: data.zip,
            phone: data.phone,
            phone_type: data.phoneType,
            email: data.email,
          },
          company_affiliation: {
            company_id: data.company,
            company_name: data.companyName,
            affiliation_id: data.affiliation_id,
            role: data.role,
            region: '0',
            lsp_cd: data.lsp_cd,
            ntid: data.ntid,
          },
          apply: [
            {
              academic_career: data.career,
              academic_plan: data.plan,
              academic_program: data.program,
              order_id: '123456789', // This is needed for the call
            },
          ],
        };
      case 'unifyAlumniStatus':
        return {
          uid: data.uid,
          isAlumni: data.isAlumni,
        };
      case 'nylStudent':
        return {
          sid: data.sid,
          temp_id: data.tempId,
          course_id: data.course,
        };
      case 'nylStudentLink':
        return {
          sid: data.sid,
          nyl_id: data.agent_id,
          password: data.password,
          docebo_id: data.docebo_id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          promo_status: data.promo_status,
          job_title: data.job_title,
          contract_type: data.contract_type,
        };
      case 'createNylStudent':
      case 'createStudent':
        return data;
      case 'updatePrimaryEmail':
        return {
          uid: data.uid,
          newPrimaryEmail: data.newPrimaryEmail,
        };
      case 'changePasswordFlow':
      case 'changeSecurityQuestion':
      case 'updateSecondaryEmail':
        return data;
      case 'updateMobile':
        return data;
      case 'verifyOTP':
        return data;
      case 'resendOTP':
        return data;
      case 'resetPwdByEmail':
        return data;
      case 'verifyNwm':
        return {
          uuid: data.uuid,
          firstName: data.firstName,
          middleName: data.middleName ?? '',
          lastName: data.lastName,
          login: data.login,
          email: data.email,
          studentID: data.studentID,
        };
      case 'couplrOptIn':
        return {
          student_id: data.studentID,
          couplr: data.couplr,
        };
      default:
        return null;
    }
  }

  return {
    load,
    save,
  };
};

export default useTacfsService;
