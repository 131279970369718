import React, { useState, useEffect } from 'react';

const Timer = () => {
  const [, setSeconds] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
      if (typeof document !== 'undefined') {
        document.querySelector('body').classList.add('overflow-auto');
        document.querySelector('body').classList.remove('overflow-hidden');
      }
    };
  }, []);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.querySelector('body').classList.remove('overflow-auto');
      document.querySelector('body').classList.add('overflow-hidden');
    }
  }, []);

  return (
    <>
      <div className="modal active timer">
        <div className="loading-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </>
  );
};

export default Timer;
