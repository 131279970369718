import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import useTacfsService from '../../utils/tacfs/useTacfsService';

/**
 * Displays a warning banner if the client is on a VPN.
 * @returns {undefined}
 */
export function VpnWarningBanner() {
  const { load } = useTacfsService();
  const [showBrowserWarning, setShowBrowserWarning] = useState(false);
  const warningCopy =
    "ATTENTION: It looks like you're using a VPN, which may affect your experience on our site. For smoother browsing, we recommend disabling your VPN.";

  const { isLoading: vpnCheckLoading, data: vpnCheck } = useQuery(
    ['vpnCheck'],
    () => load('vpnCheck'),
  );

  useEffect(() => {
    if (vpnCheckLoading === false && vpnCheck) {
      // Set whether the vpn is loading.
      setShowBrowserWarning(vpnCheck.isVpn);
    }
  }, [vpnCheckLoading, vpnCheck]);

  if (showBrowserWarning) {
    return (
      <div className="notification browserWarning">
        <div className="container--lg notif-wrapper">
          <p className="eyebrow"></p>
          <p className="desktop">{warningCopy}</p>{' '}
          <p className="mobile">{warningCopy}</p>{' '}
        </div>
      </div>
    );
  }
}
