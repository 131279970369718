import { lazy } from 'react';

const Dashboard = lazy(() => import('../pages/Dashboard'));
const LearningJourney = lazy(() => import('../pages/LearningJourney'));
const MyProfile = lazy(() => import('../pages/MyProfile'));
const Taxes = lazy(() => import('../pages/Taxes'));
const MessageCenter = lazy(() =>
  import('../components/messageCenter/MessageCenter'),
);
const LoginPage = lazy(() => import('../pages/LoginPage'));
const LoginCallback = lazy(() => import('../pages/auth/LoginCallback'));
const SSOLoginCallback = lazy(() => import('../pages/auth/SSOLoginCallback'));
const SSONwmLoginCallback = lazy(() =>
  import('../pages/auth/SSONwmLoginCallback'),
);
const SSOAdminLoginCallback = lazy(() =>
  import('../pages/auth/SSOAdminLoginCallback'),
);
const Resources = lazy(() => import('../pages/Resources'));
const TransferOfCredit = lazy(() => import('../pages/TransferOfCredit'));
const CfpTransferOfCredit = lazy(() => import('../pages/CfpTransferOfCredit'));
const PurchaseHistory = lazy(() => import('../pages/PurchaseHistory'));
const PageNotFound = lazy(() => import('../pages/PageNotFound'));
const AlumniToolkits = lazy(() => import('../pages/AlumniToolkits'));

const AddProctor = lazy(() => import('../pages/AddProctor'));
const ConfirmProctor = lazy(() => import('../pages/ConfirmProctor'));

const DesignationsCertifications = lazy(() =>
  import('../pages/DesignationsCertifications'),
);
const Recertification = lazy(() => import('../pages/Recertification'));
const EthicsQuestionnaire = lazy(() => import('../pages/EthicsQuestionnaire'));
const CEReporting = lazy(() => import('../pages/CEReporting'));
const DesignationAppSupervisor = lazy(() =>
  import('../pages/DesignationAppSupervisor'),
);
const DesignationApplication = lazy(() =>
  import('../pages/DesignationApplication'),
);
const GraduateApplication = lazy(() => import('../pages/GraduateApplication'));
const NylTransferOfCredit = lazy(() => import('../pages/NylTransferOfCredit'));

const AccountValidate = lazy(() => import('../pages/AccountValidate'));
const AccountReset = lazy(() => import('../pages/AccountReset'));
const MfaLogin = lazy(() => import('../pages/MfaLogin'));
const CreateAccount = lazy(() => import('../pages/CreateAccount'));
const CreateSSOAccount = lazy(() => import('../pages/CreateSSOAccount'));
const CompanyPortal = lazy(() => import('../pages/CompanyPortal'));
const CookiePage = lazy(() => import('../pages/CookiePage'));
const SubsLandingPage = lazy(() => import('../pages/SubsLandingPage'));

const routesList = [
  {
    path: '/',
    component: LoginPage,
    breadcrumb: 'Dashboard',
    private: false,
  },
  {
    path: '/auth/mfa',
    component: MfaLogin,
    breadcrumb: 'Sign In',
    private: false,
  },
  {
    path: '/auth/login',
    component: LoginCallback,
    breadcrumb: '',
    private: false,
  },
  {
    path: '/auth/sso/login',
    component: SSOLoginCallback,
    breadcrumb: '',
    private: false,
  },
  {
    path: '/auth/ssonwm/login',
    component: SSONwmLoginCallback,
    breadcrumb: '',
    private: false,
  },
  {
    path: '/account/nwm-setup',
    component: CreateSSOAccount,
    breadcrumb: '',
    private: true,
  },
  {
    path: '/auth/sso/admin-login/:uid',
    component: SSOAdminLoginCallback,
    breadcrumb: '',
    private: false,
  },
  {
    path: '/account/setup/:webToken',
    component: AccountValidate,
    breadcrumb: '',
    private: false,
  },
  {
    path: '/account/password-reset',
    component: AccountReset,
    breadcrumb: '',
    private: false,
  },
  {
    path: '/dashboard',
    component: Dashboard,
    breadcrumb: '',
    private: true,
  },
  {
    path: '/dashboard/messages',
    component: MessageCenter,
    breadcrumb: 'Message Center',
    private: true,
  },
  {
    path: '/designations-certifications',
    component: DesignationsCertifications,
    breadcrumb: 'My Designations & Certifications',
    private: true,
  },
  {
    path: '/designations-certifications/recertification',
    component: Recertification,
    breadcrumb: 'Recertification',
    private: true,
  },
  {
    path: '/designations-certifications/recertification/ce-reporting',
    component: CEReporting,
    breadcrumb: 'CE Reporting',
    private: true,
  },
  {
    path: '/designations-certifications/recertification/ethics-questionnaire',
    component: EthicsQuestionnaire,
    breadcrumb: 'Ethics Questionnaire',
    private: true,
  },
  {
    path: '/learning-journey',
    component: LearningJourney,
    breadcrumb: 'My Education',
    private: true,
  },
  {
    path: '/profile',
    component: MyProfile,
    breadcrumb: 'My Profile',
    private: true,
  },
  {
    path: '/my-tax-forms',
    component: Taxes,
    breadcrumb: 'My Tax Forms',
    private: true,
  },
  {
    path: '/purchase-history',
    component: PurchaseHistory,
    breadcrumb: 'My Purchase History',
    private: true,
  },
  {
    path: '/resources',
    component: Resources,
    breadcrumb: 'Resource Library',
    private: true,
  },
  {
    path: '/transfer-of-credit-application',
    component: TransferOfCredit,
    breadcrumb: 'Transfer of Credit Application',
    private: true,
  },
  {
    path: '/cfp-transfer-of-credit-application',
    component: CfpTransferOfCredit,
    breadcrumb: 'CFP Transfer of Credit Application',
    private: true,
  },
  {
    path: '/proctor-registration/:classId/add-proctor',
    component: AddProctor,
    breadcrumb: 'Add Proctor',
    private: true,
  },
  {
    path: '/proctor-registration/proctor/:term/:classNumber/agreement-confirmation',
    component: ConfirmProctor,
    breadcrumb: 'Confirm Proctor',
    private: false,
  },
  {
    path: '/company-portal',
    component: CompanyPortal,
    breadcrumb: 'Company Reports',
    private: true,
  },
  {
    path: '/designation-application-form/:designation',
    component: DesignationApplication,
    breadcrumb: 'Designation Application',
    private: true,
  },
  {
    path: '/designation-application-form/:designation/supervisor-approval',
    component: DesignationAppSupervisor,
    breadcrumb: 'Supervisor Approval',
    private: false,
  },
  {
    path: '/application-for-admission/:designation/apply',
    component: GraduateApplication,
    breadcrumb: 'Application for Admission',
    private: false,
  },
  {
    path: '/register/company/:company/program/:designation/course/TOC',
    component: NylTransferOfCredit,
    breadcrumb: 'Create Account',
    private: false,
  },
  {
    path: '/account/register',
    component: CreateAccount,
    breadcrumb: 'Create Account',
    private: false,
  },
  {
    path: '/cookie-declaration',
    component: CookiePage,
    breadcrumb: '',
    private: false,
  },
  {
    path: '/subscriptions',
    component: SubsLandingPage,
    breadcrumb: '',
    private: true,
  },
  {
    path: '/designations-certifications/toolkits/:toolkit',
    component: AlumniToolkits,
    breadcrumb: 'Toolkit',
    private: true,
  },
  {
    path: '*',
    component: PageNotFound,
    breadcrumb: '',
    private: false,
  },
];

export default routesList;
