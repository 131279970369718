import { Field } from 'formik';
import selectOptions from '../../../data/selectOptions.json';
import { validateAgainstOptions } from '../../global/AnnualInfoLock';
import Accordion from '../../subcomponents/Accordion';
import { useState, useEffect } from 'react';

export default function BasicInfoForm({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  presetVals,
}) {
  const [errorInSection, setErrorInSection] = useState(false);
  const genderOptions = selectOptions.gender;
  // Check here to make sure the values sent down from PS are considered valid by the data.
  // If not, treat the option as unselected.
  const validGender = validateAgainstOptions(values.gender, genderOptions);
  if (!validGender) {
    values.gender = '';
    presetVals.gender = false;
  }

  const hideSection =
    presetVals.ssn && presetVals.dob && presetVals.gender && validGender;

  /* highlight section title if any fields in this section have thrown an error! */
  useEffect(() => {
    const fieldArray = ['ssn', 'gender', 'ssn'];
    const matchingErrors = Object.keys(errors).filter((element) =>
      fieldArray.includes(element),
    );
    //        console.log(matchingErrors);
    if (matchingErrors.length > 0) {
      setErrorInSection(true);
    } else {
      setErrorInSection(false);
    }
  }, [errors]);

  if (hideSection) {
    return <></>;
  }

  return (
    <Accordion
      id="test1"
      title=<h4 className={errorInSection ? 'error red-text' : ''}>
        Basic Information
      </h4>
      closeTitle=<h4 className={errorInSection ? 'error red-text' : ''}>
        Basic Information
      </h4>
      iconElementClose="title3"
      iconElementOpen="title4"
    >
      <div className="column-layout">
        {!presetVals.dob && (
          <>
            <div className="col">
              <div className="field">
                <label htmlFor="birthdate-field">
                  Date of Birth <span className="req-star">*</span>
                </label>
                <Field
                  type="date"
                  id="birthdate-field"
                  name="birthdate"
                  value={values.birthdate}
                  className={errors.birthdate ? 'error' : null}
                  onChange={handleChange}
                  placeholder="Date of Birth"
                  onBlur={handleBlur}
                  required={true}
                />
                {errors.birthdate && (
                  <span className="red-text">{errors.birthdate}</span>
                )}
              </div>{' '}
            </div>
          </>
        )}
        {(!presetVals.gender || !validGender) && (
          <>
            <div className="col">
              <div className="field">
                <label htmlFor="gender-field">
                  Gender <span className="req-star">*</span>
                </label>
                <select
                  id="gender-field"
                  name="gender"
                  value={values.gender}
                  onChange={handleChange}
                  required
                >
                  {genderOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {errors.gender && (
                  <span className="red-text">{errors.gender}</span>
                )}
              </div>{' '}
            </div>
          </>
        )}
      </div>
      {!presetVals.ssn && (
        <>
          <div className="column-layout">
            <div className="col">
              <div className="field">
                <label htmlFor="ssn-field">
                  SSN <span className="req-star">*</span>
                </label>
                <Field
                  type="text"
                  id="ssn-field"
                  name="ssn"
                  value={values.ssn}
                  className={errors.ssn ? 'error' : null}
                  onChange={handleChange}
                  placeholder="Social Security Number"
                  onBlur={handleBlur}
                  required={!values.optOut}
                />
                {errors.ssn && <span className="red-text">{errors.ssn}</span>}
              </div>
              <div className="field">
                <ul className="checkboxes">
                  <li>
                    <input
                      type="checkbox"
                      id="outOut"
                      name="optOut"
                      value="Yes"
                      checked={values.optOut}
                      onChange={handleChange}
                    />
                    <label htmlFor="outOut">
                      <p>
                        I choose not to supply my Social Security Number. The
                        American College is required by the IRS to request the
                        Social Security Number for federal reporting purposes.
                      </p>
                    </label>
                  </li>
                </ul>
              </div>{' '}
            </div>
          </div>
        </>
      )}
    </Accordion>
  );
}
